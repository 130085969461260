import './editable-score.scss';
import {useEffect, useState} from "react";
import {PencilSimple} from "@phosphor-icons/react";
import Score from "./index";
import ButtonKMQ from "../Button/ButtonKMQ";

interface EditableScoreProps {
  text: string
  score: number,
  changeScore: (newScore: number) => void,
  scoreEditedBy?: string
}

const EditableScore = (props: EditableScoreProps) => {
  const {
    text,
    score,
    changeScore,
    scoreEditedBy
  } = props;

  const [edit, setEdit] = useState<boolean>(false);
  const [newScore, setNewScore] = useState<number>(-1);

  const handleEditScore = (newScore: number) => {
    if (newScore >= 0 && newScore <= 100) {
      setNewScore(newScore);
    }
  }

  const submitNewScore = () => {
    changeScore(newScore);
    setEdit(false);
  }

  useEffect(() => {
    setNewScore(-1);
  }, [edit])

  return <div className={'editable-score'}>
    <div className={'editable-score-title'} onClick={() => setEdit(!edit)}>
      <PencilSimple size={20} color={'#212121'}/>
      {text}
    </div>
    <Score
      score={newScore !== -1 ? newScore : score}
      edit={{
        canEdit: edit,
        onEditSubmit: handleEditScore
      }}
    />
    {
      edit
        ? <div className={'editable-score-save'}>
          <ButtonKMQ onClick={submitNewScore}>
            Save
          </ButtonKMQ>
        </div>
        : scoreEditedBy
          ? <div className={'editable-score-edited-by'}>
            Score edited by {scoreEditedBy}
          </div>
          : null
    }
  </div>
}

export default EditableScore;