import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizByIDURL} from "../../constants/api/quiz_endpoints";
import {useQueryClient} from "@tanstack/react-query";

interface QuizParams {
  "learning_outcomes": {
      "learning_outcome_id": string,
      "num_questions": number
    }[]
}

export const usePatchQuiz = ({quiz_id}: {quiz_id: string}) => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function patchQuiz(data: QuizParams) {
    return axios.patch(cplDraftQuizByIDURL(quiz_id), data)
      .then(response => response.data);
  }

  return useMutation({
    mutationKey: ['quiz', 'draft', quiz_id],
    mutationFn: patchQuiz,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['quiz', quiz_id, 1]})
    },
  });
}
