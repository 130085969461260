import './upload-resume.scss';
import React, {ChangeEvent, MutableRefObject, useContext, useEffect, useState} from "react";
import {DropzoneProps} from "../../../../../../components/Resume/DarkDropzone/DarkDropZone";
import {ILandingData} from "../../../../../../constants/types/LandingData";
import UploadBg from '../../../../../../assets/v2/bg/upload-bg.png';
import Button from "../../../../../../components/UI-v2/Buttons/Button";
import {ButtonType} from "../../../../../../components/UI-v2/Buttons/Button/types";
import UploadIcon from "../../../../../../assets/v2/icons/upload";
import ArrowLeft from '../../../../../../assets/v2/icons/arrow-left.png';
import ArrowRight from '../../../../../../assets/v2/icons/arrow-right.png';
import LinkedinIcon from "../../../../../../assets/v2/icons/linkedin.svg";
import ManuallyIcon from "../../../../../../assets/v2/icons/manually.svg";
import {UserContext} from "../../../../../../hooks/Context/UserContext";
import {useNavigate} from "react-router-dom";
import {ResumeContext} from "../../../../../../hooks/Context/ResumeContext";
import {PortfolioContext} from "../../../../../../hooks/Context/PortfolioContext";
import {useParseResume} from "../../../../../../api/normalization/useParseResume";
import TermsOfUseModal from "../../../../../../components/TermsOfUse";
import LoadingProgressBar from "../../../../../../components/UI-v2/Loading/LoadingProgressBar";
import LinkedinHelp from "../LinkedinHelp";
import UploadRules from "./UploadRules";

interface UploadResumeProps extends DropzoneProps {
  landingPageInfo: ILandingData,
  fileInputRef: MutableRefObject<HTMLInputElement | null>
}

const UploadResume: React.FC<UploadResumeProps> = (
  {landingPageInfo, onFileUploaded, onManualFill, onParseError, fileInputRef}
) => {

  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [manualChosen, setManualChosen] = useState<boolean>(false);
  const [onFileDragged, setOnFileDragged] = useState<boolean>(false);
  const [showLinkedin, setShowLinkedin] = useState<boolean>(false);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const {mutate, isPending} = useParseResume();

  const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && validFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      alert('Invalid file type. Please upload a PDF, DOC, or DOCX file.');
    }
  };

  /*const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };*/

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFile = event.dataTransfer.files[0];
    if (newFile && validFileTypes.includes(newFile.type)) {
      setFile(newFile);
    } else {
      alert('Invalid file type. Please upload a PDF, DOC, or DOCX file.');
    }
  };

  const handleChooseManual = () => {
    if (!user?.permission_groups?.length) {
      setShowTerms(true);
      setManualChosen(true);
    } else {
      onManualFill();
    }
  }

  const onSubmit = () => {
    if (user?.permission_groups?.length) {
      handleSubmit();
    } else {
      handleShowTerms();
    }
  }

  const handleShowTerms = () => {
    setShowTerms(true);
  }

  const handleLinkedInClick = () => {
    setShowLinkedin(true)
  };

  const handleSubmit = () => {
    if (manualChosen) {
      onManualFill();
      return;
    }
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    if (user.isSoft) {
      localStorage.removeItem('portfolio_id');
      localStorage.removeItem('refresh');
      localStorage.removeItem('marketplace_user');
    }
    mutate(formData, {
      onSuccess: (response) => {
        if (portfolio.start_over) {
          setPortfolio({id: response.portfolio_id, start_over: true});
        } else {
          setPortfolio({id: response.portfolio_id});
        }
        setParsedResume(response);
        if (onFileUploaded) {
          onFileUploaded();
        }
      },
      onError: (error: any) => {
        if (error.response?.status === 400 && error.response?.data?.error === "Unable to parse resume") {
          setManualChosen(true);
          onParseError();
          onManualFill();
        } else {
          console.error('Error uploading file', error);
        }
      },
    });
  };

  useEffect(() => {
    if (file) {
      onSubmit();
    }
  }, [file])

  return <div className={'upload-resume'}>
    <LoadingProgressBar
      loading={isPending}
      text={'Uploading your resumé'}
      logo={landingPageInfo.logo}
    />
    <div className={'upload-resume-header'}>
      <span className={'upload-resume-header-text caption'}>
        <span className={'upload-resume-header-text-title'}>{landingPageInfo.cpl_term_full}</span>
        <span className={'upload-resume-header-text-desc'}>{' | Free initial assessment'}</span>
        <span className={'upload-resume-header-text-mobile'}>{'Free initial assessment - just upload your resumé'}</span>
      </span>
    </div>
    <h1 className={'upload-resume-title'}>
      Get course credits for your work & life experience
    </h1>
    <div className={'text-body-large upload-resume-subtitle'}
         dangerouslySetInnerHTML={{__html: landingPageInfo.intro_text}}
    />

    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      accept=".doc,.docx,.pdf"
    />

    <div className={'upload-resume-box'}
         onDrop={onDrop}
         onDragOver={(event) => event.preventDefault()}
         onDragEnter={() => setOnFileDragged(true)}
    >
      {
        onFileDragged ?
          <div
            className={'upload-resume-box-ondrop'}
            onDragLeave={() => setOnFileDragged(false)}
            onClick={() => setOnFileDragged(false)}
          >
            <h3>Drop file here</h3>
          </div>
          : null
      }
      <img
        src={ArrowLeft}
        alt={'arrow-left'}
        className={'upload-resume-box-arrow-left'}
      />
      <img
        src={ArrowRight}
        alt={'arrow-right'}
        className={'upload-resume-box-arrow-right'}
      />

      <img
        src={UploadBg}
        alt={'background'}
        className={'upload-resume-box-bg'}
      />

      <div className={'upload-resume-box-button'}>
        <Button onClick={() => fileInputRef?.current ? fileInputRef.current.click() : () => {}}
                type={ButtonType.tool}>
          <UploadIcon/> Upload Resume
        </Button>
      </div>
      <h5 className={'upload-resume-box-drag'}>
        or drag & drop
      </h5>
      <div className={'upload-resume-box-paste text-body-xxs'}>
        Paste file
        <span className={'upload-resume-box-paste-button text-body-xxs'}>
          {'Ctrl '}
        </span>
        +
        <span className={'upload-resume-box-paste-button text-body-xxs'}>
          {' V'}
        </span>
      </div>
      <div className={'upload-resume-box-file caption-small'}>
        File types
      </div>
      <UploadRules/>
    </div>

    <div className={'upload-resume-mobile-box'}>
      <Button onClick={() => fileInputRef?.current ? fileInputRef.current.click() : () => {}}
              type={ButtonType.large}>
        <UploadIcon/> Upload Resume
      </Button>
      <UploadRules/>
    </div>

    <div className={'upload-resume-benefits text-body-regular'}>
      <span className={'upload-resume-benefits-item'}>
        ✓ <span className={'upload-resume-benefits-item-desc'}>
        Reduce tuition costs
      </span>
      </span>
      <span className={'upload-resume-benefits-item'}>
        ✓ <span className={'upload-resume-benefits-item-desc'}>
          Get credit exemption
        </span>
      </span>
      <span className={'upload-resume-benefits-item'}>
        ✓ <span className={'upload-resume-benefits-item-desc'}>
          Graduate faster
        </span>
      </span>
    </div>

    <h6 className={'upload-resume-linkedin'}>
      Don’t have a resumé?
    </h6>
    <div className={'upload-resume-footer'}>
      <Button onClick={handleLinkedInClick} type={ButtonType.pill}>
        <img src={LinkedinIcon} alt={'linkedin'}/> Use your LinkedIn profile
      </Button>
      <Button onClick={handleChooseManual} type={ButtonType.pill}>
        <img src={ManuallyIcon} alt={'manually'}/> Fill out manually
      </Button>
    </div>

    <TermsOfUseModal
      open={showTerms}
      onClose={handleSubmit}
    />

    <LinkedinHelp
      showModal={showLinkedin}
      setShowModal={setShowLinkedin}
      landingPageInfo={landingPageInfo}
    />
  </div>
}

export default UploadResume;