import "./plar-card.scss";
import PlarCardStatus from "./components/CardStatus/PlarCardStatus";
import ButtonKMQ from "../../Button/ButtonKMQ";
import StatusChiclet from "../../Chiclets/StatusChiclet/StatusChiclet";
import { ButtonTypes } from "../../../../constants/ui/types/ButtonTypes";
import HeartEmpty from "../../../../assets/icons/UI/icons/heart-empty.svg";
import HeartSelected from "../../../../assets/icons/UI/icons/heart-selected.svg";
import PaymentChiclet from "../../Chiclets/PaymentChiclet/PaymentChiclet";
import WarningIcon from "../../../../assets/icons/error/warning.svg";

export enum PlarCardType {
	"Accepted" = "green",
	"Completed" = "green",
	"In_progress" = "yellow",
	"Rejected" = "red",
	"Not_started" = "white",
}

export interface PlarCardProps {
	type?: PlarCardType;
	title: string;
	subtitle?: string;
	cartNumber?: string;
	header?: string;
	match?: string;
	onClick: () => void;
	buttonText: string;
	chicletText?: string;
	statusText?: string[];
	cpl_eligible?: boolean;
	additionalButton?: {
		onClick: () => void;
		buttonText: string;
	};
	showFavourite?: {
		active: boolean;
		toggleActive: () => void;
	};
	showFavouriteAdmin?: boolean;
	num_paid_recommendations?: number;
	has_quiz?: boolean;
	active_catalogue_version?: boolean;
}

const PlarCard = (props: PlarCardProps) => {
	const {
		type = PlarCardType.Not_started,
		title,
		subtitle,
		cartNumber,
		header,
		onClick,
		buttonText,
		cpl_eligible,
		additionalButton,
		showFavourite,
		showFavouriteAdmin,
		num_paid_recommendations,
		active_catalogue_version,
		has_quiz,
	} = props;

	const renderFavouriteIcon = (clickable: boolean) => {
		return (
			<div
				className={`plar-card-favourite ${
					clickable ? "" : "plar-card-favourite-disabled"
				}`}
			>
				{num_paid_recommendations && num_paid_recommendations > 0 ? (
					<PaymentChiclet
						text={`${num_paid_recommendations} ${
							num_paid_recommendations > 1 ? "courses" : "course"
						} paid`}
					/>
				) : null}
				<img
					src={showFavourite?.active || !clickable ? HeartSelected : HeartEmpty}
					alt={"heart"}
					onClick={clickable ? showFavourite?.toggleActive : undefined}
					className={"plar-card-favourite-icon"}
				/>
				<span className={"plar-card-favourite-text"}>Favourite</span>
			</div>
		);
	};

	return (
		<div className={`plar-card plar-card-${type}`}>
			{cpl_eligible ? (
				<StatusChiclet
					text={
						window.screen.width > 420
							? "Credit for Prior Learning Eligible"
							: "CPL Eligible"
					}
				/>
			) : null}
			{header ? <div className={"plar-card-header"}>{header}</div> : null}

			{showFavouriteAdmin ? renderFavouriteIcon(false) : null}

			<div className={"plar-card-title"}>{title}</div>

			{subtitle ? <div className={"plar-card-subtitle"}>{subtitle}</div> : null}

			{cartNumber ? (
				<div className={"plar-card-subtitle"}>{cartNumber}</div>
			) : null}

			{showFavourite ? (
				renderFavouriteIcon(true)
			) : (
				<PlarCardStatus {...props} />
			)}

			<div className={"plar-card-footer"}>
				<span className={"plar-card-footer-labels"}>
					{has_quiz && (
						<span className={"plar-card-footer-due"}>
							<span className={"plar-card-footer-due-dot"} />
							<span>Quiz requested</span>
						</span>
					)}
					{
						!active_catalogue_version
							? <span className={'course-card-footer-warning'}>
								<img
									src={WarningIcon}
									alt={'warning'}
								/>
								<span className={'course-card-footer-warning-text'}>
									Attention required
								</span>
							</span>
							: null
					}
				</span>
				<div className="button-wrapper">
					<ButtonKMQ onClick={onClick}>{buttonText}</ButtonKMQ>
					{additionalButton && (
						<ButtonKMQ
							onClick={additionalButton.onClick}
							type={ButtonTypes.Secondary}
						>
							{additionalButton.buttonText}
						</ButtonKMQ>
					)}
				</div>
			</div>
		</div>
	);
};

export default PlarCard;
