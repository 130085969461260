import './landing-page.scss';
import React, {useEffect, useRef, useState} from "react";
import {DropzoneProps} from "../../../../components/Resume/DarkDropzone/DarkDropZone";
import Button from "../../../../components/UI-v2/Buttons/Button";
import {ButtonType} from "../../../../components/UI-v2/Buttons/Button/types";
import UploadIcon from "../../../../assets/v2/icons/upload";
import {useGetLandingInfo} from "../../../../api/general/useGetLandingInfo";
import NavBar, {NavBarOptions} from "../../../../components/UI-v2/NavBar";
import UploadResume from "./components/UploadResume/UploadResume";
import HowItWorks from "./components/HowItWorks";
import Eligibility from "./components/Eligibility";
import WhatIsPlar from "./components/WhatIsPlar";
import Benefits from "./components/Benefits";
import CollegeProcess from "./components/CollegeProcess";
import FAQ from "./components/FAQ";
import NeedHelp from "./components/NeedHelp";
import Footer from "../../../../components/UI-v2/Footer";
import LandingPageUpload from "./components/LandingPageUpload";

const LandingPage: React.FC<DropzoneProps> = ({onFileUploaded, onManualFill, onParseError}) => {
  const {data} = useGetLandingInfo();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [activeTab, setActiveTab] = useState<NavBarOptions | undefined>(undefined);

  const onUploadClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  }

  useEffect(() => {
    const isInViewport = (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      return rect.top <= 0 && rect.bottom >= 0;
    }
    const onScroll = () => {
      for (let optionId in NavBarOptions) {
        let currElem = document.getElementById(optionId);
        if (currElem && isInViewport(currElem)) {
          // @ts-ignore
          setActiveTab(optionId);
          return;
        }
      }
    };

    window.addEventListener("scroll", onScroll);
    try {
      document.getElementsByClassName('soft-user-header')[0].className += ' hide';
      document.getElementsByClassName('footer')[0].className += ' hide';
      document.getElementsByClassName('edit-resume-bottom-navigation')[0].className += ' hide';
    } catch (e) {
      console.log(e);
    }


    return () => {
      window.removeEventListener("scroll", onScroll);
      try {
        document.getElementsByClassName('soft-user-header')[0].className = 'soft-user-header';
        document.getElementsByClassName('footer')[0].className += 'footer';
        let bottomNav = document.getElementsByClassName('edit-resume-bottom-navigation')[0];
        bottomNav.className = bottomNav.className.replace('hide', '');
      } catch (e) {
        console.log(e);
      }

    }
  }, []);

  if (!data) {
    return null;
  }

  return <div className={'landing-page'}>
    <NavBar
      icon={data.logo}
      cpl_term={data.cpl_term}
      active={activeTab}
      onUploadClick={onUploadClick}
    />

    <UploadResume
      onFileUploaded={onFileUploaded}
      onManualFill={onManualFill}
      onParseError={onParseError}
      landingPageInfo={data}
      fileInputRef={fileInputRef}
    />

    <LandingPageUpload onUploadClick={onUploadClick}/>
    <HowItWorks landingPageInfo={data}/>
    <Eligibility landingPageInfo={data}/>
    <WhatIsPlar landingPageInfo={data}/>
    <Benefits landingPageInfo={data}/>
    <CollegeProcess landingPageInfo={data}/>
    <FAQ/>
    <NeedHelp landingPageInfo={data}/>

    <Footer/>

  </div>
}

export default LandingPage;
