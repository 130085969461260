import {useQuery} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplQuizResultsURL} from "../../constants/api/quiz_endpoints";

export const useGetQuizResults = ({assessment_id, portfolio_id}: {portfolio_id?: string, assessment_id?: string}) => {
  const axios = useAxios();

  const handleRequest = () => {
    if (portfolio_id && assessment_id) {
      return axios.get(cplQuizResultsURL, {
        params: {
          assessment_id: assessment_id,
          portfolio_id: portfolio_id
        }
      })
        .then(response => response?.data ?? response);
    }
  }

  return useQuery({
    queryKey: ['quiz-results', assessment_id, portfolio_id],
    queryFn: handleRequest,
    retry: 1
  })

}
