import moment from "moment/moment";

export interface IDate {
  year?: string,
  month?: string,
  day?: string
}

export const monthsOptions = [
  {label: 'January', value: 'January'},
  {label: 'February', value: 'February'},
  {label: 'March', value: 'March'},
  {label: 'April', value: 'April'},
  {label: 'May', value: 'May'},
  {label: 'June', value: 'June'},
  {label: 'July', value: 'July'},
  {label: 'August', value: 'August'},
  {label: 'September', value: 'September'},
  {label: 'October', value: 'October'},
  {label: 'November', value: 'November'},
  {label: 'December', value: 'December'},
];

export const yearsOptions: { label: string, value: string }[] = [];

for (let i = Number(moment().format('YYYY')); i > 1949; --i) {
  yearsOptions.push({label: i.toString(), value: i.toString()})
}

export const yearsFutureOptions: { label: string, value: string }[] = [];

for (let i = Number(moment().format('YYYY')); i < 2100; ++i) {
  yearsFutureOptions.push({label: i.toString(), value: i.toString()})
}