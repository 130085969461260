import SelectKMQ from "../../../components/UI/Select/SelectKMQ";
import {IDate, monthsOptions, yearsFutureOptions} from "../../../constants/resume/dates";
import moment from "moment/moment";
import React, {useEffect} from "react";
import ErrorKMQ from "../../../components/UI/Error/ErrorKMQ";

interface DueDateProps {
  dueDate: IDate,
  setDueDate: (newDate: IDate) => void,
  error: string
}

const QuizDueDate = (props: DueDateProps) => {
  const {
    dueDate,
    setDueDate,
    error
  } = props;

  useEffect(() => {
    if (dueDate.month && dueDate.day) {
      if (!moment(`${dueDate.year ? dueDate.year : moment().format('YYYY')}-${dueDate.month}-${dueDate.day}`, 'YYYY-MMMM-DD').isValid()) {
        setDueDate({...dueDate, day: undefined});
      }
    }
  }, [dueDate, setDueDate])

  return <div className={'draft-quiz-duedate'}>
      <SelectKMQ
        options={monthsOptions}
        value={dueDate.month
          ? {
            label: dueDate.month,
            value: dueDate.month
          }
          : null
        }
        onChange={(newMonth) => setDueDate({...dueDate, month: newMonth.value})}
        placeholder={'Month'}
      />
      <SelectKMQ
        disabled={!dueDate.month}
        options={Array.from({length: moment(`${dueDate.year ? dueDate.year : moment().format('YYYY')}-${moment().month(dueDate.month ? dueDate.month : '0').format("MM")}`).daysInMonth()}, (_, i) => {
          return {
            label: i + 1,
            value: i + 1
          }
        })}
        value={dueDate.day
          ? {
            label: dueDate.day,
            value: dueDate.day
          }
          : null
        }
        onChange={(newDay) => setDueDate({...dueDate, day: newDay.value})}
        placeholder={'Date'}
      />
      <SelectKMQ
        options={yearsFutureOptions}
        value={dueDate.year
          ? {
            label: dueDate.year,
            value: dueDate.year
          }
          : null
        }
        onChange={(newYear) => setDueDate({...dueDate, year: newYear.value})}
        placeholder={'Year'}
      />
    <ErrorKMQ error={error}/>
    </div>
}

export default QuizDueDate;