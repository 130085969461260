import {useEffect, useState} from "react";
import Loading from "../Loading";

interface LoadingProps {
  loading?: boolean,
  text?: string,
}

const SmartLoading = (props: LoadingProps) => {
  const {
    loading,
    text
  } = props;

  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setShowProgressBar(true), 3000)
    }
  }, [loading])

  if (showProgressBar) {
    return <Loading
      loading={loading}
      text={text}
      showProgress
    />
  }

  if (loading) {
    return <Loading
      loading={loading}
    />
  }

  return null;
}

export default SmartLoading;