import {useQuery} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplQuizRequestURL} from "../../constants/api/quiz_endpoints";

export const useGetQuizRequest = ({course_id, portfolio_id}: {portfolio_id?: string, course_id?: string}) => {
  const axios = useAxios();

  const handleRequest = () => {
    if (portfolio_id && course_id) {
      return axios.get(cplQuizRequestURL, {
        params: {
          course_id: course_id,
          portfolio_id: portfolio_id
        }
      })
        .then(response => response?.data ?? response);
    }
  }

  return useQuery({
    queryKey: ['quiz-request', course_id, portfolio_id],
    queryFn: handleRequest,
    retry: 1
  })

}
