import './applicant-course-details.scss';
import Back from "../../../../../../components/UI/Back/Back";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useContext, useMemo} from "react";
import {PortfolioContext} from "../../../../../../hooks/Context/PortfolioContext";
import {useQueryPrograms} from "../../../../../../api/admin/useQueryPrograms";
import {useQueryCourses} from "../../../../../../api/admin/useQueryCourses";
import {testing_institution_id} from "../../../../../../constants/api/institution_id";
import {ICourse} from "../../../../../admin/courses/AdminCourses";
import Loading from "../../../../../../components/UI/Loading/Loading";
import Notification from "../../../../../../components/UI/Notification/Notification";
import {UserContext} from "../../../../../../hooks/Context/UserContext";
import ButtonKMQ from "../../../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../../../constants/ui/types/ButtonTypes";
import NotificationRequired from "../../../../../../components/UI/Notification/NotificationRequired";

const ApplicantCourseDetails = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {program_id, course_code} = useParams();
  const [portfolio] = useContext(PortfolioContext);

  const {data: currProgram, isLoading: programIsLoading} = useQueryPrograms({
    portfolio_id: portfolio.id,
    program_id: program_id,
    userIsSoft: user.isSoft
  });

  const {data: courses} = useQueryCourses({
    portfolio_id: portfolio.id,
    program_id: program_id,
    institution_id: testing_institution_id,
    course_code: course_code,
    userIsSoft: user.isSoft
  });

  const currCourse = useMemo(() => {
    if (courses && courses?.data?.courses?.length > 0) {
      return courses?.data?.courses.find((item: ICourse) => item.code === course_code)
    }
  }, [course_code, courses])

  if (!currCourse || programIsLoading) {
    return <Loading loading/>
  }

  return <div className={'applicant-course-details'}>
    {
      !location.pathname.includes('quiz')
        ? <Back
          text={`Back to ${currProgram.name}`}
          onClick={() => navigate(`/cpl-applicant/my-programs/${program_id}`)}
        />
        : null
    }
    {
      !currCourse.active_catalogue_version
        ? <NotificationRequired>
          Attention required: Criteria for this course has recently been updated. An administrator will contact you with next steps.
        </NotificationRequired>
        : null
    }
    <Notification>
      {"Information provided is subject to change and may not reflect the most recent updates. For the most accurate and up-to-date information, please refer to "}
      <a href={currProgram.catalogue_url} target={'_blank'} rel={'noreferrer'}>
        {currProgram.catalogue_url}
      </a>.
    </Notification>

    {
      !location.pathname.includes('quiz') && !!currCourse?.quiz_request?.id
        ? <div className={'applicant-course-details-quiz'}>
            <ButtonKMQ
              onClick={() => navigate(location.pathname + `/quiz`)}
              type={currCourse?.quiz_request?.completed ? ButtonTypes.Secondary : ButtonTypes.Tertiary}
            >
              {currCourse?.quiz_request?.completed ? 'View answers' : 'Start quiz'}
            </ButtonKMQ>
          </div>
        : null
    }

    <div className={'applicant-course-details-code'}>
      {currCourse.code}
    </div>

    <div className={'applicant-course-details-title'}>
      {currCourse.title}
    </div>

    <table className={'applicant-course-details-mobile-header'}>
      <td>
        <div className={'applicant-course-details-right-subtitle'}>
          Credit hours
        </div>
        <div className={'applicant-course-details-right-text'}>
          {currCourse.credit_hours ?? 'Not available'}
        </div>
      </td>

      <td>
        <div className={'applicant-course-details-right-subtitle'}>
          Campus location
        </div>
        <div className={'applicant-course-details-right-text'}>
          {currCourse.location ?? 'Not available'}
        </div>
      </td>
    </table>

    <table>
      <tr>
        <td className={'applicant-course-details-left'}>
          <div className={'applicant-course-details-left-subtitle'}>
            Course description
          </div>
          <div className={'applicant-course-details-left-text'}>
            {currCourse.description}
          </div>

          <div className={'applicant-course-details-left-subtitle'}>
            Course outcomes
          </div>
          <div
            className={'applicant-course-details-left-text'}
            dangerouslySetInnerHTML={{__html: currCourse.learning_outcome_html}}
          />

          <div className={'applicant-course-details-left-subtitle applicant-course-details-left-mobile'}>
            Prerequisites
          </div>
          <div className={'applicant-course-details-left-text applicant-course-details-left-mobile'}>
            {
              currCourse.prerequisite_courses
                ? currCourse.prerequisite_courses.map((item: { code: string }) => item.code).join(', ')
                : 'None.'
            }
          </div>

          <div className={"applicant-course-details-left-divider"}/>
        </td>

        <td className={'applicant-course-details-right'}>
          <div className={'applicant-course-details-right-subtitle'}>
            Credit hours
          </div>
          <div className={'applicant-course-details-right-text'}>
            {currCourse.credit_hours ?? 'Not available'}
          </div>

          <div className={'applicant-course-details-right-subtitle'}>
            Prerequisites
          </div>
          <div className={'applicant-course-details-right-text'}>
            {
              currCourse.prerequisite_courses
                ? currCourse.prerequisite_courses.map((item: { code: string }) => item.code).join(', ')
                : 'None.'
            }
          </div>

          <div className={'applicant-course-details-right-subtitle'}>
            Campus location
          </div>
          <div className={'applicant-course-details-right-text'}>
            {currCourse.location ?? 'Not available'}
          </div>
        </td>
      </tr>
    </table>
  </div>
}

export default ApplicantCourseDetails;