import { useState, useEffect, useRef } from 'react';
import './checkbox-filter.scss';
import ButtonKMQ from '../Button/ButtonKMQ';
import { ButtonTypes } from '../../../constants/ui/types/ButtonTypes';
import Filter from "../../../assets/icons/UI/icons/filter.svg";
import OpenIcon from '../../../assets/icons/UI/select/arrow-down.svg';
import CloseIcon from '../../../assets/icons/UI/select/arrow-up.svg';

interface FilterOption {
  label: string;
  value: string;
}

interface CheckboxFilterProps {
  title: string;
  options: FilterOption[];
  value: FilterOption[];
  onChange: (value: FilterOption[]) => void;
  className?: string;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  title,
  options,
  value,
  onChange,
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>(value);
  const filterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (option: FilterOption) => {
    const newSelection = selectedOptions.find(item => item.value === option.value)
      ? selectedOptions.filter(item => item.value !== option.value)
      : [...selectedOptions, option];
    setSelectedOptions(newSelection);
  };

  const handleApply = () => {
    onChange(selectedOptions);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedOptions(value);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div className={`checkbox-filter ${className}`} ref={filterRef}>
      <button
        className="filter-button"
        onClick={toggleDropdown}
      >
        <img
          src={Filter}
          alt="filter"
          className="sorting-active-icon"
        />
        <span>Filter by: {title}</span>
        <img
          src={isOpen ? CloseIcon : OpenIcon}
          alt={isOpen ? 'close-menu' : 'open-menu'}
          className="sorting-active-open-btn"
        />
      </button>

      {isOpen && (
        <div className="filter-dropdown">
          <div className="filter-options">
            {options.map((option) => (
              <label key={option.value} className="checkbox-option">
                <input
                  type="checkbox"
                  checked={!!selectedOptions.find(item => item.value === option.value)}
                  onChange={() => handleCheckboxChange(option)}
                  className="checkbox-input"
                />
                <span className="checkbox-label">{option.label}</span>
              </label>
            ))}
          </div>
          <div className="filter-actions">
            <button
              className="reset-button"
              onClick={handleReset}
            >
              Reset filters
            </button>
            <div className="action-buttons">
              <ButtonKMQ
                type={ButtonTypes.Secondary}
                onClick={handleCancel}
              >
                Cancel
              </ButtonKMQ>
              <ButtonKMQ
                type={ButtonTypes.Primary}
                onClick={handleApply}
              >
                Apply
              </ButtonKMQ>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckboxFilter;