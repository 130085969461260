import { useMutation } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

interface CompleteQuizParams {
    assessment_id: string;
    portfolio_id: string;
}

export const useMutateCompleteQuiz = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (params: CompleteQuizParams) => {
            const response = await axios.post(
                '/cpl-course-quizzes/quiz/complete',
                params
            );
            return response.data;
        }
    });
};