import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

interface InactiveApplicantsParams {
  filter_action_type?: string[];
  filter_date_type?: string;
  start_date?: string;
  end_date?: string;
  search_term?: string;
  current_page?: number;
  items_per_page?: number;
}

export const useQueryInactiveApplicants = (
  params: InactiveApplicantsParams,
  options?: Omit<UseQueryOptions<any, Error>, 'queryKey' | 'queryFn'>
) => {
  const axios = useAxios();
  
  const handleRequest = () => {
    return axios.get(
      '/core-plar-recommendation/inactive-cpl-user-list',
      { params }
    )
    .then(response => response?.data ?? response);
  };

  return useQuery({
    queryKey: ['inactive-applicants', params.current_page, params.search_term, params.filter_action_type, params.filter_date_type, params.start_date, params.end_date],
    queryFn: handleRequest,
    ...options,
  });
};