import {useQuery} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizOutcomesByIDURL} from "../../constants/api/quiz_endpoints";

export const useGetDraftQuizOutcomes = ({quiz_id}: {quiz_id?: string}) => {
  const axios = useAxios();

  const handleRequest = () => {
    if (quiz_id) {
      return axios.get(cplDraftQuizOutcomesByIDURL(quiz_id), {
        params: {
          is_missing: true
        }
      })
        .then(response => response?.data);
    }
  }

  return useQuery({
    queryKey: ['quiz', 'outcomes', quiz_id],
    queryFn: handleRequest,
    retry: 1
  })

}
