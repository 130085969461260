import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizQuestionURL} from "../../constants/api/quiz_endpoints";
import {IQuizQuestion} from "../../constants/types/Quiz";

interface QuizQuestionParams {
  "question_id": string,
  "question": IQuizQuestion
}

export const useEditQuizQuestion = ({quiz_id, current_page}: { quiz_id: string, current_page: number }) => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function editQuestion(data: QuizQuestionParams) {
    return axios.patch(cplDraftQuizQuestionURL(data.question_id), data.question)
      .then(response => response.data);
  }

  return useMutation({
    mutationKey: ['quiz', quiz_id, 'question', 'edit'],
    mutationFn: editQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['quiz', quiz_id, current_page]})
    },
  });
}
