import './navbar.scss';
import React, {useEffect, useState} from "react";
import Button from "../Buttons/Button";
import UploadIcon from "../../../assets/v2/icons/upload";
import MenuIcon from "../../../assets/v2/icons/menu.svg";
import CloseIcon from "../../../assets/v2/icons/cross.svg";
import {useNavigate} from "react-router-dom";
import {ButtonType} from "../Buttons/Button/types";

export enum NavBarOptions {
  'how_it_works' = 'how_it_works',
  'eligibility' = 'eligibility',
  'what_is_plar' = 'what_is_plar',
  'benefits' = 'benefits',
  'faqs' = 'faqs'
}

interface NavBarProps {
  icon: string,
  cpl_term: string,
  active?: NavBarOptions,
  onUploadClick: () => void
}

const NavBar = (props: NavBarProps) => {
  const {
    icon,
    active,
    cpl_term,
    onUploadClick
  } = props;

  const navigate = useNavigate();
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);

  const handleScroll = (scrollTo: string) => {
    let element = document.getElementById(scrollTo);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
    setMobileMenu(false);
  }

  const renderNavbar = () =>
    <span>
      <span
        className={`navbar-item ${active === NavBarOptions.how_it_works ? 'navbar-item-active' : ''}`}
        onClick={() => handleScroll(NavBarOptions.how_it_works)}
      >
        How it works
      </span>
      <span className={`navbar-item ${active === NavBarOptions.eligibility ? 'navbar-item-active' : ''}`}
            onClick={() => handleScroll(NavBarOptions.eligibility)}
      >
        Eligibility
      </span>
      <span className={`navbar-item ${active === NavBarOptions.what_is_plar ? 'navbar-item-active' : ''}`}
            onClick={() => handleScroll(NavBarOptions.what_is_plar)}
      >
        What is {cpl_term}
      </span>
      <span className={`navbar-item ${active === NavBarOptions.benefits ? 'navbar-item-active' : ''}`}
            onClick={() => handleScroll(NavBarOptions.benefits)}
      >
        Benefits
      </span>
      <span className={`navbar-item ${active === NavBarOptions.faqs ? 'navbar-item-active' : ''}`}
            onClick={() => handleScroll(NavBarOptions.faqs)}
      >
        FAQs
      </span>
    </span>

  return <div className={'navbar'}>
    <span className={'navbar-item navbar-item-logo'} onClick={() => navigate('/cpl-applicant')}>
      <img
        src={icon}
        alt={'college-logo'}
      />
    </span>

    <span className={'navbar-desktop'}>
      {renderNavbar()}
    </span>

    <span className={'navbar-right'}>
      <span className={'navbar-item navbar-right-login'} onClick={() => navigate('/login')}>
        Log In
      </span>
      <Button onClick={onUploadClick}>
        <UploadIcon/> Upload Resume
      </Button>
    </span>

    <span className={'navbar-right navbar-right-mobile'}>
      <img
        src={mobileMenu ? CloseIcon : MenuIcon}
        alt={'navigation menu'}
        onClick={() => setMobileMenu(!mobileMenu)}
      />
    </span>

    {
      mobileMenu
        ? <div className={'navbar-menu'}>
          {renderNavbar()}
          <div className={'navbar-menu-button'}>
            <Button onClick={onUploadClick} type={ButtonType.large}>
              <UploadIcon/> Upload Resume
            </Button>
          </div>
        </div>
        : null
    }
  </div>
}

export default NavBar;