import React, { useState, ChangeEvent, useRef, useContext } from 'react';
import { XCircle, Paperclip, ArrowBendRightDown, CloudArrowUp } from '@phosphor-icons/react';
import './darkdropzone.scss';
import { useParseResume } from '../../../api/normalization/useParseResume';
import { PortfolioContext } from '../../../hooks/Context/PortfolioContext';
import { ResumeContext } from '../../../hooks/Context/ResumeContext';
import Loading from '../../UI/Loading/Loading';
import ButtonKMQ from '../../UI/Button/ButtonKMQ';
import { UserContext } from "../../../hooks/Context/UserContext";
import TermsOfUseModal from "../../TermsOfUse";
import Linkedin from '../../../app/login/linkedin-page/Linkedin';
import { useNavigate } from 'react-router-dom';

export interface DropzoneProps {
  onFileUploaded?: () => void;
  onManualFill: () => void;
  onParseError: () => void;
}

const DarkDropZone: React.FC<DropzoneProps> = ({ onFileUploaded, onManualFill, onParseError }) => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showLinkedIn, setShowLinkedIn] = useState<boolean>(false);
  const [manualChosen, setManualChosen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const { mutate, isPending } = useParseResume();
  
  const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && validFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      alert('Invalid file type. Please upload a PDF, DOC, or DOCX file.');
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFile = event.dataTransfer.files[0];
    if (newFile && validFileTypes.includes(newFile.type)) {
      setFile(newFile);
    } else {
      alert('Invalid file type. Please upload a PDF, DOC, or DOCX file.');
    }
  };

  const handleChooseManual = () => {
    if (!user?.permission_groups?.length) {
      setShowTerms(true);
      setManualChosen(true);
    } else {
      onManualFill();
    }
  }

  const onSubmit = () => {
    if (user?.permission_groups?.length) {
      handleSubmit();
    } else {
      handleShowTerms();
    }
  }

  const handleShowTerms = () => {
    setShowTerms(true);
  }

  const handleLinkedInClick = () => {
    navigate('/linkedin');
  };

  const handleSubmit = () => {
    if (manualChosen) {
      onManualFill();
      return;
    }
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    if (user.isSoft) {
      localStorage.removeItem('portfolio_id');
      localStorage.removeItem('refresh');
      localStorage.removeItem('marketplace_user');
    }
    mutate(formData, {
      onSuccess: (response) => {
        if (portfolio.start_over) {
          setPortfolio({id: response.portfolio_id, start_over: true});
        } else {
          setPortfolio({id: response.portfolio_id});
        }

        // if education or work experience array is empty from parse-resume endpoint, 
        // initialize it with a default entry
        if (!response.education || response.education.length === 0) {
          response.education = [{ program: '' }];
        }

        if (!response.work_experience || response.work_experience.length === 0) {
          response.education = [{ company: '', job_title: '', job_summary: '' }];
        }
        
        setParsedResume(response);
        if (onFileUploaded) {
          onFileUploaded();
        }
      },
      onError: (error: any) => {
        if (error.response?.status === 400 && error.response?.data?.error === "Unable to parse resume") {
          setManualChosen(true);
          onParseError();
          onManualFill();
        } else {
          console.error('Error uploading file', error);
        }
      },
    });
  };

  return (
    <>
      {showLinkedIn ? (
        <Linkedin />
      ) : (
        <>
          <Loading text={"Uploading Resume"} loading={isPending} showProgress/>
          <div
            className="dark-dropzone-container"
            onDragOver={(event) => event.preventDefault()}
            onDrop={onDrop}
          >
            <div className="upload-box">
              <h1>Upload your resume <ArrowBendRightDown size={65}/></h1>
              <div className="upload-box-upload-area">
                <label className="upload-box-upload-area-upload-label">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".doc,.docx,.pdf"
                  />
                  <CloudArrowUp size={32} className="upload-box-upload-area-upload-label-upload-icon"/>
                  <p className="upload-box-upload-area-upload-label-upload-instructions">
                    Drag or click here to upload your resume
                  </p>
                </label>

                <p className="upload-box-upload-area-upload-instructions">
                  Please only use the following file formats: .docx, .doc, .pdf<br/>
                  Max file size: 10MB | Max files: 1
                </p>

                {file && (
                  <div className="upload-box-uploaded-file">
                    <Paperclip size={32}/>
                    <span className="file-name">{file.name} ({(file.size / 1024).toFixed(1)}KB)</span>
                    <XCircle size={32} onClick={handleRemoveFile} className="remove-file"/>
                  </div>
                )}

                <div className="upload-box-or-separator">
                  <span>OR</span>
                </div>

                <p className="upload-box-manual-entry-link" onClick={handleChooseManual}>
                  <span>Click here</span> to fill in the form manually
                </p>

                <p className="upload-box-linkedin-text">
                  Don't have a resume? Learn how to{' '}
                  <span onClick={handleLinkedInClick} className="linkedin-link">
                    export your LinkedIn profile
                  </span>{' '}
                  as a pdf
                </p>

                <ButtonKMQ onClick={onSubmit} disabled={!file} className="upload-box-upload-button">
                  Upload
                </ButtonKMQ>
              </div>
            </div>
          </div>

          <TermsOfUseModal
            open={showTerms}
            onClose={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default DarkDropZone;