import './quiz.outcomes.scss';
import {useParams} from "react-router-dom";
import {useGetDraftQuizOutcomes} from "../../../../api/quiz/useGetDraftQuizOutcomes";
import Loading from '../../../../components/UI/Loading/Loading';
import {IQuizOutcome} from "../../../../constants/types/Quiz";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import React, {useEffect, useState} from "react";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";
import {usePatchQuiz} from "../../../../api/quiz/usePatchQuiz";
import AlertModal from "../../../../components/UI/Modals/AlertModal/AlertModal";
import {CheckCircle} from "@phosphor-icons/react";

const QuizOutcomes = ({onBack, learningOutcomes}: { onBack: () => void, learningOutcomes: string[] }) => {
  const {quiz_id} = useParams();
  const {data} = useGetDraftQuizOutcomes({quiz_id});
  const [selectedOutcomes, setSelectedOutcomes] = useState<string[]>([]);
  const {mutate: generateQuestions, isSuccess, isPending} = usePatchQuiz({quiz_id: quiz_id ? quiz_id : ''});
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleAddAll = () => {
    if (data?.learning_outcomes.length === selectedOutcomes.length) {
      setSelectedOutcomes([]);
    } else {
      setSelectedOutcomes(data?.learning_outcomes.map((item: IQuizOutcome) => item.id))
    }
  }

  const handleAddOutcome = (id: string) => {
    let filteredSelected = selectedOutcomes.filter((item: string) => item !== id);
    if (filteredSelected.length !== selectedOutcomes.length) {
      setSelectedOutcomes(filteredSelected);
    } else {
      setSelectedOutcomes([...selectedOutcomes, id]);
    }
  }

  const handleGenerateQuestions = () => {
    generateQuestions({
      learning_outcomes: [
        ...learningOutcomes.concat(selectedOutcomes).map((item: string) => {
          return {
            'learning_outcome_id': item,
            'num_questions': 1
          }
        })
      ]
    })
  }

  const closeModal = () => {
    setShowModal(false);
    onBack();
  }

  useEffect(() => {
    if (isSuccess) {
      setShowModal(true);
      /*toast.success('New questions were successfully added to the quiz.')
      onBack();*/
    }
  }, [isSuccess])

  if (!data) {
    return <Loading loading={true}/>
  }

  return <div className={'quiz-outcomes'}>
    <Loading loading={isPending}/>
    <div className={'quiz-outcomes-title'}>
      Select More Outcomes
    </div>

    <Checkbox
      handleClick={handleAddAll}
      active={data?.learning_outcomes.length === selectedOutcomes.length}
      text={'Add all to quiz'}
    />

    {
      data.learning_outcomes.map((item: IQuizOutcome, index: number) =>
        <div key={index} className={'quiz-outcomes-option'}>
          <div className={'quiz-outcomes-option-text'}>
            {index + 1}. {item.sentence}
          </div>
          <Checkbox
            handleClick={() => handleAddOutcome(item.id)}
            active={selectedOutcomes.includes(item.id)}
            text={'Add to quiz'}
          />
        </div>
      )
    }

    <div className={'quiz-outcomes-footer'}>
      <ButtonKMQ onClick={onBack} type={ButtonTypes.Secondary}>
        Back
      </ButtonKMQ>
      <ButtonKMQ onClick={handleGenerateQuestions}>
        Generate Questions
      </ButtonKMQ>
    </div>

    <AlertModal
      open={showModal}
      onClose={closeModal}
      icon={<CheckCircle size={56} color="#212121"/>}
      text={'Your questions have been generated.'}
      onProceed={closeModal}
      proceedText={'Done'}
    />
  </div>
}

export default QuizOutcomes;