import './quiz-results.scss';
import {useGetQuizResults} from "../../../../api/quiz/useGetQuizResults";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useContext} from "react";
import {PortfolioContext} from "../../../../hooks/Context/PortfolioContext";
import {usePostQuizAnnotation} from "../../../../api/quiz/postQuizAnnotation";
import Breadcrumbs from "../../../../components/UI/Breadcrumbs/Breadcrumbs";
import {useQueryPrograms} from "../../../../api/admin/useQueryPrograms";
import moment from "moment";
import Score from "../../../../components/UI/Score";
import QuestionResult from "./QuestionResult";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import SmartLoading from "../../../../components/UI/Loading/SmartLoading";

const QuizResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [portfolio] = useContext(PortfolioContext);
  const {portfolio_id, program_id, course_code, quiz_id} = useParams();
  //const [currPage, setCurrPage] = useState<number>(1);

  const {mutate} = usePostQuizAnnotation({
    assessment_id: quiz_id,
    portfolio_id: portfolio_id
  });

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id,
  });

  const {data: quizData, isPending} = useGetQuizResults({
    portfolio_id: portfolio_id,
    assessment_id: quiz_id
  });

  const handleScoreEdit = (id: string, score: number) => {
    mutate({
      id: id,
      body: {
        score: score
      }
    })
  }

  const handleNoteEdit = (id: string, note: string) => {
    mutate({
      id: id,
      body: {
        note: note
      }
    })
  }

  return <div className={'quiz-results'}>
    <SmartLoading
      loading={isPending}
      text={'Analyzing quiz results'}
    />

    <Breadcrumbs content={[
      {
        label: 'Applicants',
        link: `/cpl-admin`
      },
      {
        label: `${portfolio.portfolio_user_name}’s profile`,
        link: `/cpl-admin/${portfolio_id}`
      },
      {
        label: `${currProgram?.name}`,
        link: `/cpl-admin/${portfolio_id}/${program_id}`
      },
      {
        label: `${quizData?.course?.title}`,
        link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`
      },
      {
        label: `Quiz Results`,
        link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}/quiz/${quiz_id}/results`
      }
    ]}/>

    <div className={'quiz-results-title'}>
      Quiz Result
    </div>

    <div className={'quiz-results-overview'}>
      <div className={'quiz-results-overview-left'}>
        <div className={'quiz-results-overview-left-title'}>
          Details
        </div>
        <div className={'quiz-results-overview-left-subtitle'}>
          Course: {quizData?.course?.title}
        </div>
        <div className={'quiz-results-overview-left-subtitle'}>
          Quiz date: {moment(quizData?.quiz_request?.created_at).format('MM/DD/YYYY')}
        </div>
        <div className={'quiz-results-overview-left-subtitle'}>
          Due date: {moment(quizData?.quiz_request?.due_date).format('MM/DD/YYYY')}
        </div>
      </div>
      <div className={'quiz-results-overview-right'}>
        <div className={'quiz-results-overview-right-title'}>
          Average score
        </div>
        <Score score={quizData?.quiz_request?.score ?? 0}/>

      </div>
    </div>

    <div className={'quiz-results-header'}>
      <span className={'quiz-results-header-left'}>
        Answers
      </span>
      <span className={'quiz-results-header-rigth'}>
        AI Evaluation
      </span>
    </div>

    {
      quizData?.questions && quizData.questions.map((item: any, index: number) =>
        <QuestionResult
          index={index + 1}
          question_text={item.question_text}
          answer_text={item.user_response.ai_answer_text}
          score={item.user_response.score}
          scoreEditedBy={item.user_response.score_updated_by?.first_name ? (item.user_response.score_updated_by.first_name + ' ' + item.user_response.score_updated_by.last_name) : ''}
          onScoreEdit={(newScore: number) => handleScoreEdit(item.user_response.id, newScore)}
          note={item.user_response.note}
          onNoteEdit={(newNote: string) => handleNoteEdit(item.user_response.id, newNote)}
          generated_by_ai_confidence={item.user_response.generated_by_ai_confidence}
        />)
    }

    <div className={'quiz-results-footer'}>
      <ButtonKMQ onClick={() => navigate(`/cpl-admin/${portfolio_id}/${program_id}/${course_code}`)}>
        Back to Course Details
      </ButtonKMQ>
    </div>
  </div>
}

export default QuizResults;