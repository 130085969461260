import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import Breadcrumbs from "../../../../components/UI/Breadcrumbs/Breadcrumbs";
import NavigationTabs from "../../../../components/UI/NavigationTabs/NavigationTabs";
import React, {useContext} from "react";
import {PortfolioContext} from "../../../../hooks/Context/PortfolioContext";
import {useGetDraftQuiz} from "../../../../api/quiz/useGetDraftQuiz";
import {useQueryPrograms} from "../../../../api/admin/useQueryPrograms";

const QuizCourseDetails = () => {
  const navigate = useNavigate();
  const [portfolio] = useContext(PortfolioContext);
  const {portfolio_id, program_id, course_code, quiz_id} = useParams();

  const {data: draftQuiz} = useGetDraftQuiz({
    quiz_id: quiz_id,
    current_page: 1
  });

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id
  });

  return <div className={'draft-quiz'}>
    <Breadcrumbs content={[
      {
        label: 'Applicants',
        link: `/cpl-admin`
      },
      {
        label: `${portfolio.portfolio_user_name}’s profile`,
        link: `/cpl-admin/${portfolio_id}`
      },
      {
        label: `${currProgram?.name}`,
        link: `/cpl-admin/${portfolio_id}/${program_id}`
      },
      {
        label: `${draftQuiz?.course?.title}`,
        link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`
      },
      {
        label: 'Request Quiz',
        link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}/quiz/${quiz_id}`
      }
    ]}/>

    <div className={'draft-quiz-title'}>
      Request Quiz
    </div>

    <NavigationTabs
      tabs={[
        {
          name: 'Request Quiz',
          active: false,
          onClick: () => {navigate(`/cpl-admin/${portfolio_id}/${program_id}/${course_code}/quiz/${quiz_id}`)}
        },
        {
          name: 'Course details',
          active: true,
          onClick: () => {}
        }
      ]}
    />

    <Outlet/>
  </div>

}

export default QuizCourseDetails;