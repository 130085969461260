import './quiz.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useGetDraftQuiz} from "../../../api/quiz/useGetDraftQuiz";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import React, {useContext, useState} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import NavigationTabs from "../../../components/UI/NavigationTabs/NavigationTabs";
import QuizTemplate from "./QuizTemplate";
import Loading from "../../../components/UI/Loading/Loading";
import QuizOutcomes from "./QuizOutcomes";
import {IQuizQuestion} from "../../../constants/types/Quiz";
import NotificationRequired from "../../../components/UI/Notification/NotificationRequired";
import QuizDueDate from "./QuizDueDate";
import {IDate} from "../../../constants/resume/dates";

const DraftQuiz = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [portfolio] = useContext(PortfolioContext);
  const {portfolio_id, program_id, course_code, quiz_id} = useParams();
  const [pageMode, setPageMode] = useState<'template' | 'outcomes'>('template');
  const [currPage, setCurrPage] = useState<number>(1);
  const [dueDate, setDueDate] = useState<IDate>({});
  const [errorDate, setErrorDate] = useState<string>('');

  const {data: draftQuiz} = useGetDraftQuiz({
    quiz_id: quiz_id,
    current_page: currPage
  });

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id
  });

  if (!draftQuiz) {
    return <Loading loading/>
  }

  return <div className={'draft-quiz'}>
    <Breadcrumbs content={[
      {
        label: 'Applicants',
        link: `/cpl-admin`
      },
      {
        label: `${portfolio.portfolio_user_name}’s profile`,
        link: `/cpl-admin/${portfolio_id}`
      },
      {
        label: `${currProgram?.name}`,
        link: `/cpl-admin/${portfolio_id}/${program_id}`
      },
      {
        label: `${draftQuiz?.course?.title}`,
        link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`
      },
      {
        label: 'Request Quiz',
        link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}/quiz/${quiz_id}`
      }
    ]}/>

    <div className={'draft-quiz-title'}>
      Request Quiz
    </div>

    <NavigationTabs
      tabs={[
        {
          name: 'Request Quiz',
          active: true,
          onClick: () => {
          }
        },
        {
          name: 'Course details',
          active: false,
          onClick: () => {
            navigate(location.pathname + '/course-details')
          }
        }
      ]}
    />

    <div className={'draft-quiz-subtitle'}>
      Course: {draftQuiz?.course?.title}
    </div>

    <NotificationRequired>
      Please set a due date and review the questions.
    </NotificationRequired>

    <div className={'draft-quiz-subtitle'}>
      Due date
    </div>

    <QuizDueDate
      dueDate={dueDate}
      setDueDate={setDueDate}
      error={errorDate}
    />


    <div className={'draft-quiz-mode'}>
      <span
        className={`draft-quiz-mode-option ${pageMode === 'template' ? 'draft-quiz-mode-option-active' : ''}`}
        onClick={() => setPageMode('template')}
      >
        Quiz Template
      </span>
      <span
        className={`draft-quiz-mode-option ${pageMode === 'outcomes' ? 'draft-quiz-mode-option-active' : ''}`}
        onClick={() => setPageMode('outcomes')}
      >
        Select More Outcomes
      </span>
    </div>

    <div>
      {
        pageMode === 'template'
          ? <QuizTemplate
            questions={draftQuiz?.questions}
            currPage={currPage}
            setCurrPage={setCurrPage}
            totalPages={draftQuiz.total_pages}
            dueDate={dueDate}
            errorDate={[errorDate, setErrorDate]}
          />
          : <QuizOutcomes
            onBack={() => {
              setPageMode('template');
              setCurrPage(1);
            }}
            learningOutcomes={draftQuiz.questions.map((item: IQuizQuestion) => item.learning_outcome.id)}
          />
      }
    </div>
  </div>
}

export default DraftQuiz;