import './college-process.scss';
import {ILandingData} from "../../../../../../constants/types/LandingData";

const CollegeProcess = ({landingPageInfo}: { landingPageInfo: ILandingData }) => {
  const listValues = [
    {
      title: '✓  Free evaluation',
      subtitle: 'Check eligibility for all potential credits for free. Pay only for the course credits you want applied to your transcript.'
    },
    {
      title: '✓  Fast assessment',
      subtitle: `Get assessed in minutes with minimal paperwork. Months faster than traditional ${landingPageInfo.cpl_term} process.`
    },
    {
      title: '✓  Transparency',
      subtitle: 'Your experience matters.  See how your work and education aligns and qualifies you for credits with our accurate confidence % measures.'
    },
    {
      title: '✓  Secure & confidential',
      subtitle: 'Your data is securely stored and only shared with college administrators when you officially apply for credits.'
    }
  ];

  return <div className={'college-process'}>
    <img
      src={landingPageInfo.plar_description_section.image}
      alt={landingPageInfo.plar_description_section.image_alt}
      className={'college-process-image'}
    />
    <h2 className={'college-process-title'}>
      We’re here to help you on your path to graduation and workplace success.
    </h2>
    <div
      className={'text-body-large college-process-desc'}
      dangerouslySetInnerHTML={{__html: landingPageInfo.plar_description_section.description}}
    />
    <h5
      className={'college-process-subtitle'}
      dangerouslySetInnerHTML={{__html: landingPageInfo.plar_description_section.sub_title}}
    />

    <div className={'college-process-list'}>
      {
        listValues.map((item, index: number) =>
          <div key={index} className={'college-process-list-item'}>
            <h4 className={'college-process-list-item-title'}>
              {item.title}
            </h4>
            <div className={'text-body-small'}>
              {item.subtitle}
            </div>
          </div>
        )
      }
    </div>
  </div>
}

export default CollegeProcess;