import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";
import { QuizParams, QuizResponse } from "../../constants/types/QuizTypes";

export const useQueryQuiz = (params: QuizParams) => {
	const { course_id, portfolio_id, items_per_page, current_page } = params;
	const axios = useAxios();

	const handleRequest = () => {
		const queryParams: Record<string, any> = {
			course_id,
			portfolio_id,
			items_per_page,
			current_page,
		};

		return axios
			.get<QuizResponse>("/cpl-course-quizzes/quiz", { params: queryParams })
			.then((response) => response.data);
	};

	return useQuery({
		queryKey: ["quiz", params],
		queryFn: handleRequest,
		retry: 1,
	});
};
