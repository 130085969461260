import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizURL} from "../../constants/api/quiz_endpoints";

interface QuizParams {
  "course_id": number,
  "learning_outcomes": {
      "learning_outcome_id": number,
      "num_questions": number
    }[]
}

export const usePostQuiz = () => {
  const axios = useAxios();

  async function postQuiz(data: QuizParams) {
    return axios.post(cplDraftQuizURL, data)
      .then(response => response.data);
  }

  return useMutation({
    mutationKey: ['quiz', 'draft'],
    mutationFn: postQuiz,
  });
}
