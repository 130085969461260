import React from "react";
import "./save-icon.scss";
import { SaveFillSvg, SaveLineSvg } from "../../../../assets/icons/save/save-icon.svg";

interface SaveIconProps {
  saving: boolean;
  className?: string;
  isInitial?: boolean;
}

const SaveIcon: React.FC<SaveIconProps> = ({ saving, className = "", isInitial = false }) => {
  const getState = () => {
    if (isInitial) return "initial";
    if (saving) return "saving";
    return "saved";
  };
  return (
    <span className={`save-status ${getState()} ${className}`}>
      <span className="save-icon">
      {saving || isInitial ? <SaveLineSvg /> : <SaveFillSvg />}
      </span>
      <span className="status-text">
        {isInitial ? "Save" : saving ? "Saving..." : "Saved"}
      </span>
    </span>
  );
};

export default SaveIcon;