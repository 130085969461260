import './landing-page-upload.scss';
import Button from "../../../../../../components/UI-v2/Buttons/Button";
import {ButtonType} from "../../../../../../components/UI-v2/Buttons/Button/types";
import UploadIcon from "../../../../../../assets/v2/icons/upload";
import React from "react";

const LandingPageUpload = ({onUploadClick}: { onUploadClick: () => void }) => {
  return <div className={'landing-page-upload'}>
    <div>
      <div className={'text-body-xl landing-page-upload-text'}>
        Our AI-enabled tool assesses your resumé and instantly shows you what course credits you could be eligible
        for.
      </div>
      <div className={'text-body-xl landing-page-upload-extension'}>
        It’s free to use and only takes five minutes.
      </div>
    </div>

    <div className={'landing-page-upload-button'}>
      <Button onClick={onUploadClick} type={ButtonType.large}>
        <UploadIcon/> Upload Resume
      </Button>
    </div>
  </div>
}

export default LandingPageUpload;