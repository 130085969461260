import './about-plar.scss';
import {NavBarOptions} from "../../../../../../components/UI-v2/NavBar";
import {ILandingData} from "../../../../../../constants/types/LandingData";

const WhatIsPlar = ({landingPageInfo}: { landingPageInfo: ILandingData }) => {
  return <div className={'about-plar'} id={NavBarOptions.what_is_plar}>
    <div className={'text-label about-plar-title'}>
      What is {landingPageInfo.cpl_term_full} ({landingPageInfo.cpl_term})?
    </div>
    <div className={'text-body-large about-plar-desc'}>
      {landingPageInfo.cpl_term_full} ({landingPageInfo.cpl_term}) allows adult learners at Fleming College to gain credits for selected courses.
    </div>
    <div className={'text-body-large about-plar-desc about-plar-desc-last'}>
      If you have existing skills from previous work, education, volunteer, or military experiences, you may be eligible.
    </div>

    <div className={'about-plar-affiliated'}>
      <span className={'text-body-regular about-plar-affiliated-text'}>
        Affiliated with
      </span>
      {
        landingPageInfo.affiliated_bodies_images.map((item: string, index: number) =>
          <img
            src={item}
            alt={'affiliate' + index}
            key={index}
          />
        )
      }
    </div>
  </div>
}

export default WhatIsPlar;