import './linkedin-modal.scss';
import Modal from "react-modal";
import CloseIcon from '../../../../../../assets/v2/icons/close-icon.svg';
import LinkedinImage from '../../../../../../assets/v2/images/linkedin-info.png';
import LinkedinImage2 from '../../../../../../assets/v2/images/linkedin-info2.png';
import {ILandingData} from "../../../../../../constants/types/LandingData";

const LinkedinHelp = ({
                        showModal,
                        setShowModal,
                        landingPageInfo
                      }: { showModal: boolean, setShowModal: (newState: boolean) => void, landingPageInfo: ILandingData }) => {
  const instructions = [
    {
      text: "Go to <a href='https://www.linkedin.com' target={'_blank'} rel='noreferrer'> www.linkedin.com</a>",
      image: null
    },
    {
      text: 'Click the “Me” button at the top of the page and then select “View Profile” from the dropdown menu.',
      image: LinkedinImage
    },
    {
      text: 'On your Profile page, click the “Resources” button and then click “Save to PDF”.',
      image: LinkedinImage2
    },
    {
      text: `Upload your PDF into our <a href="${landingPageInfo.cpl_website}" target="_blank"> course credits tool </a> and follow the steps to see which credits you are eligible for.`,
      image: null
    }
  ]
  return <Modal
    isOpen={showModal}
    onRequestClose={() => setShowModal(false)}
    portalClassName={'linkedin-modal'}
    contentLabel="Linkedin Modal"
  >

    <div className={'linkedin-modal-close text-body-small'}>
      <img
        src={CloseIcon}
        alt={'close'}
        className={'linkedin-modal-close-icon'}
        onClick={() => setShowModal(false)}
      />
      Close
    </div>

    <div className={'linkedin-modal-body'}>
      <h1 className={'linkedin-modal-title'}>
        Save a LinkedIn profile as a PDF.
      </h1>
      <div className={'text-body-small linkedin-modal-subtitle'}>
        On the
        <a href={'https://www.linkedin.com'} target={'_blank'} rel="noreferrer">
          {' LinkedIn '}
        </a>
        website, you can export your profile as a PDF. You can then upload this PDF to our AI-enabled tool to check your
        course credits eligibility. Just follow these steps:
      </div>

      {
        instructions.map((item, index: number) =>
          <div className={'linkedin-modal-instruction'}>
            <h6 className={'linkedin-modal-instruction-index'}>
              {index + 1}
            </h6>
            <h4
              className={'linkedin-modal-instruction-text'}
              dangerouslySetInnerHTML={{__html: item.text}}
            />
            {
              item.image
                ? <div className={'linkedin-modal-instruction-image'}>
                  <img
                    src={item.image}
                    alt={'Linkedin instruction page'}
                  />
                </div>
                : null
            }
          </div>
        )
      }

      <h3 className={'linkedin-modal-help'}>
        Need help?
      </h3>
      <div
        className={'text-body-small linkedin-modal-contact'}
        dangerouslySetInnerHTML={{__html: landingPageInfo.plar_support_html}}
      />

    </div>
  </Modal>
}

export default LinkedinHelp;