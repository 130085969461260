import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizQuestionURL} from "../../constants/api/quiz_endpoints";

interface QuizQuestionParams {
  "question_id": string,
}

export const useDeleteQuizQuestion = ({quiz_id, current_page}: {quiz_id: string, current_page: number}) => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function deleteQuestion(data: QuizQuestionParams) {
    return axios.delete(cplDraftQuizQuestionURL(data.question_id))
      .then(response => response.data);
  }

  return useMutation({
    mutationKey: ['quiz', quiz_id, 'question', 'delete'],
    mutationFn: deleteQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['quiz', quiz_id, current_page]})
    },
  });
}
