import {useQuery} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizByIDURL} from "../../constants/api/quiz_endpoints";

export const useGetDraftQuiz = ({quiz_id, current_page}: {quiz_id?: string, current_page?: number}) => {
  const axios = useAxios();

  const handleRequest = () => {
    if (quiz_id) {
      return axios.get(cplDraftQuizByIDURL(quiz_id), {
        params: {
          current_page,
          items_per_page: 5
        }
      })
        .then(response => response?.data);
    }
  }

  return useQuery({
    queryKey: ['quiz', quiz_id, current_page],
    queryFn: handleRequest,
    retry: 1
  })

}
