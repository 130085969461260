import "./quiz.intro.scss";
import { QuizResponse } from "../../../constants/types/QuizTypes";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import Back from "../../../components/UI/Back/Back";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface QuizIntroProps {
  program_id?: string,
	quiz: QuizResponse;
	closeIntro: () => void;
}

const QuizIntro = (props: QuizIntroProps) => {
	const { program_id, quiz, closeIntro } = props;

	const navigate = useNavigate();

	return (
		<>
			<div className="quiz-header">
				<Back
					text={`Back to ${quiz.meta.assessment_name || "My Programs"}`}
					onClick={() => navigate(`/cpl-applicant/my-programs/${program_id}`)}
				/>
			</div>
			<div className={"quiz-intro-container"}>
				<div className={"quiz-intro"}>
					<div className={"quiz-intro-title"}>Quiz</div>
					<div className={"quiz-intro-subtitle"}>
						Course: {quiz.meta.assessment_name}
					</div>
					<div className={"quiz-intro-subtitle"}>
						Due date: {moment(quiz.quiz_request.due_date, 'YYYY-MM-DD').format('MMMM DD, YYYY').toString()}
					</div>
					<div className={"quiz-intro-body"}>
						Please read the following instructions carefully before starting the quiz:
					</div>

					<ul>
						{quiz.meta.description.map((item: string, index: number) => (
							<li className={"quiz-intro-description"} key={index}>
								{item}
							</li>
						))}
					</ul>

					<div className={"quiz-intro-body"}>Good luck with your quiz!</div>
					<div className={"quiz-intro-footer"}>
						<ButtonKMQ onClick={closeIntro}>Start quiz</ButtonKMQ>
					</div>
				</div>
			</div>
		</>
	);
};

export default QuizIntro;
