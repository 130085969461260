const UploadIcon = ({color}: { color?: string }) => {
  const iconSize = 24;
  const iconColor = color ? color : "#007AFF";

  return <svg width={iconSize} height={iconSize} viewBox={`0 0 ${iconSize} ${iconSize}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_218_94)">
      <path
        d="M15 17.2405H18.5625C21.1406 17.2405 23.25 15.8713 23.25 13.3217C23.25 10.7722 20.7656 9.50282 18.75 9.40298C18.3333 5.41579 15.4219 2.99048 12 2.99048C8.76562 2.99048 6.6825 5.13688 6 7.26548C3.1875 7.53267 0.75 9.32235 0.75 12.253C0.75 15.1836 3.28125 17.2405 6.375 17.2405H9"
        stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 11.9905L12 8.99048L9 11.9905M12 21.0102V9.74048" stroke={iconColor} strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_218_94">
        <rect width={iconSize} height={iconSize} fill="white"/>
      </clipPath>
    </defs>
  </svg>
}

export default UploadIcon;