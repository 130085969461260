import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {landingInfoURL} from "../../constants/api/endpoints";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useGetLandingInfo = () => {

  const handleRequest = async () => {
    const {data} = await axios.get(backendBaseUrl + landingInfoURL);
    return data;
  }

  return useQuery({
    queryKey: ['landing-info'],
    queryFn: handleRequest
  });
}