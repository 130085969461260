import './faq.scss';
import {useQueryFAQ} from "../../../../../../api/faq/useQueryFAQ";
import TextDropdown from "../../../../../../components/UI-v2/TextDropdown";
import {NavBarOptions} from "../../../../../../components/UI-v2/NavBar";

interface IQuestion {
  title: string,
  content: string
}

const FAQ = () => {
  const {data} = useQueryFAQ({type: 'landing-page'});
  let colLength = data ? Math.floor(data.length / 2) : 0;

  if (!data) {
    return null;
  }

  return <div className={'faqs'} id={NavBarOptions.faqs}>
    <div className={'text-label faqs-title'}>
      Frequently asked questions
    </div>
    <div className={'faqs-questions'}>
      <div className={'faqs-questions-col'}>
        {
          data.slice(0, colLength).map((item: IQuestion, index: number) =>
            <div key={index}>
              <TextDropdown
                text={item.title}
                description={item.content}
              />
            </div>
          )
        }
      </div>

      <div className={'faqs-questions-col'}>
        {
          data.slice(colLength, data.length).map((item: IQuestion, index: number) =>
            <div key={index}>
              <TextDropdown
                text={item.title}
                description={item.content}
              />
            </div>
          )
        }
      </div>
    </div>
  </div>
}

export default FAQ;