import './notification.scss';
import {WarningCircle} from "@phosphor-icons/react";

const NotificationRequired = ({children}: { children: React.ReactNode }) => {

  return <div className={'notification notification-required'}>
    <WarningCircle
      size={28}
      color="#212121"
      className={'notification-required-warning'}
    />
    <span className={'notification-content notification-required-content'}>
      {children}
    </span>
  </div>
}

export default NotificationRequired;