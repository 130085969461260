import './question-result.scss';
import EditableScore from "../../../../../components/UI/Score/EditableScore";
import {PencilSimple} from "@phosphor-icons/react";
import {useEffect, useState} from "react";
import ButtonKMQ from "../../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../../constants/ui/types/ButtonTypes";
import WarningIcon from "../../../../../assets/icons/UI/icons/warning.svg";

interface QuestionResultProps {
  index: number,
  question_text: string,
  answer_text: string,
  score: number,
  onScoreEdit: (newScore: number) => void,
  note?: string,
  onNoteEdit: (newNote: string) => void,
  scoreEditedBy?: string,
  generated_by_ai_confidence: number
}

const QuestionResult = (props: QuestionResultProps) => {
  const {
    index,
    question_text,
    answer_text,
    score,
    onScoreEdit,
    note,
    onNoteEdit,
    scoreEditedBy,
    generated_by_ai_confidence
  } = props;

  const [editNote, setEditNote] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>('');

  const cancelEditNote = () => {
    setEditNote(false);
    setNewNote(note ? note : '');
  }

  const saveNewNote = () => {
    setEditNote(false);
    onNoteEdit(newNote);
  }

  useEffect(() => {
    setNewNote(note ? note : '');
  }, [note])

  return <div className={'question-result'}>
    <div className={'question-result-left'}>
      <div className={'question-result-left-question'}>
        {index}. {question_text}
      </div>
      <div
        className={'question-result-left-answer'}
        dangerouslySetInnerHTML={{__html: answer_text}}
      />
    </div>
    <div className={'question-result-right'}>
      <EditableScore
        text={'Edit score'}
        score={score}
        changeScore={onScoreEdit}
        scoreEditedBy={scoreEditedBy}
      />
    </div>

    {generated_by_ai_confidence >= 50
      ? <div className={'question-result-ai'}>
        <img
          src={WarningIcon}
          alt={'warning'}
        />
        <span className={'question-result-ai-text'}>
          {`Certainty percentage: ${generated_by_ai_confidence}%. Answer may have been generated by AI`}
        </span>
      </div>
      : null
    }

    <div className={'question-result-note'}>
      <div className={'question-result-note-header'}>
        <div className={'question-result-note-header-title'}>
          Add Note
        </div>
        <div className={'question-result-note-header-edit'}
             onClick={() => setEditNote(!editNote)}>
          <PencilSimple size={20} color={'#212121'}/>
          Add note
        </div>
      </div>
      <div className={'question-result-note-field'}>
        {
          editNote
            ? <textarea
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder={''}

            />
            : <div className={'question-result-note-field-text'}>
              {note}
            </div>
        }
      </div>

      {
        editNote
          ? <div className={'question-result-note-footer'}>
            <ButtonKMQ onClick={cancelEditNote} type={ButtonTypes.Secondary}>
              Cancel
            </ButtonKMQ>
            <ButtonKMQ onClick={saveNewNote}>
              Save
            </ButtonKMQ>
          </div>
          : null
      }
    </div>
  </div>
}

export default QuestionResult;