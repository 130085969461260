import './eligibility.scss';
import {ILandingData} from "../../../../../../constants/types/LandingData";
import {NavBarOptions} from "../../../../../../components/UI-v2/NavBar";

const Eligibility = ({landingPageInfo}: { landingPageInfo: ILandingData }) => {
  const listText = [
    'Adult learners seeking postsecondary credits',
    'Military veterans transitioning into civilian life.',
    'Recently laid off workers and mid-career shifters who are seeking a quick path to retrain or switch careers.',
    'Individuals who have been out of school for a long time—or have never attended postsecondary but are ready to start.',
    'Adults with previous education, work, military or volunteer experience... you may qualify for course credits but you didn’t know it!',
    'People who want to embark on a new career, attain their dream job, and make a meaningful difference.',
    `Individuals who attended school outside of ${landingPageInfo.country}, have immigration status and are eligible to work.`,
    `Don't fit any categories on the list? Feel free to contact us to help you determine what will be a good fit`,
  ]

  return <div className={'eligibility'} id={NavBarOptions.eligibility}>
    <div className={'text-label eligibility-title'}>
      Who is it right for?
    </div>

    <ul className={'eligibility-list'}>
      {listText.map((item: string, index: number) =>
        <li key={index} className={'text-body-large'}>
          {item}
        </li>
      )}
    </ul>
  </div>
}

export default Eligibility;