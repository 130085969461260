import {useQuery} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {inactiveUsersExportUrl, paidCoursesCsvReportURL} from "../../constants/api/endpoints";
import {useFileDownload} from "./useFileDownload";

export const useQueryCSV = () => {

   const { downloadFile } = useFileDownload();

  const downloadCsvReport = async () => {
    return downloadFile(paidCoursesCsvReportURL, {});
  };

  return { downloadCsvReport };

  /*const axios = useAxios();

  const getUserInfo = () => {
    return axios.get(paidCoursesCsvReportURL, {
    }).then(data => data.data)
  }

  return useQuery({
    queryKey: ['paid-courses-csv'],
    queryFn: getUserInfo,
    enabled: false,
    retry: false
  })*/
}