import './text-dropdown.scss';
import PlusIcon from '../../../assets/v2/icons/plus.svg';
import { useState } from 'react';

interface DropdownProps {
  text: string,
  description: string
}

const TextDropdown = (props: DropdownProps) => {
  const {
    text,
    description
  } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  return <div className={'text-dropdown'}>
    <h5 className={'text-dropdown-main'} onClick={() => setIsOpened(!isOpened)}>
      {text}

      <img
        src={PlusIcon}
        alt={isOpened ? 'close' : 'open'}
        className={`text-dropdown-main-image ${isOpened ? 'text-dropdown-main-image-close' : ''}`}
      />
    </h5>
    <div
      className={`text-body-small text-dropdown-body 
      ${isOpened ? 'text-dropdown-body-opened' : ''}
       ${isOpened && description.length < 200 ? 'text-dropdown-body-small' : ''}
      `}
      dangerouslySetInnerHTML={{__html: description}}
    />
  </div>
}

export default TextDropdown;