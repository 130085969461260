import './button.scss';
import {ButtonType} from './types';
import {PropsWithChildren} from "react";

interface ButtonProps {
  type?: ButtonType,
  onClick: () => void,
  disabled?: boolean,
}

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    type = ButtonType.regular,
    onClick,
    disabled,
    children
  } = props;



  return <button
    className={`btn-v2 btn-v2-${type}`}
    disabled={disabled}
    onClick={onClick}>
    {
      type === ButtonType.pill
      ? <h6>{children}</h6>
      : children
    }
  </button>
}

export default Button;