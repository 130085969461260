import './applicant-profile.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {ISortingOption} from "../../../../components/UI/SearchAndSort/SearchAndSort";
import {programsSortingOptionsAdmin} from "../../../../constants/ui/sortingOptions";
import {useQueryReport} from "../../../../api/admin/useQueryReport";
import {useQueryUserInfo} from "../../../../api/admin/useQueryUserInfo";
import {IProgram} from "../../../../constants/types/IProgram";
import {PlarCardType} from "../../../../components/UI/Cards/Plar/PlarCard";
import moment from "moment/moment";
import Loading from "../../../../components/UI/Loading/Loading";
import UserProfile from "../../../../components/UI/Profile/UserProfile";
import {PDFDownloadLink} from "@react-pdf/renderer";
import ReportFile from "../../../../components/Report/ReportFile";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import Info from "../../../../components/UI/Info/Info";
import ComplexSearch from "../../../../components/UI/ComplexSearch/ComplexSearch";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";
import {useAssignPortfolio} from "../../../../api/admin/useAssignPortfolio";
import Toggle from "../../../../components/UI/Toggle/Toggle";
import AlertModal from "../../../../components/UI/Modals/AlertModal/AlertModal";
import Breadcrumbs from "../../../../components/UI/Breadcrumbs/Breadcrumbs";
import {useFinishPortfolioReview} from "../../../../api/admin/useFinishPortfolioReview";
import {PortfolioContext} from "../../../../hooks/Context/PortfolioContext";
import {toast} from "react-toastify";
import ProgramAdminCard from "../../../../components/UI/Cards/Plar/ProgramAdminCard";
import Pagination from "../../../assessments/components/EmployeeTablePagination/Pagination";
import Notification from '../../../../components/UI/Notification/Notification';
import {profileFilterOptions} from '../../../../constants/ui/profileFilterOptions';
import ApplicantInformation from './ApplicantInformation/ApplicantInformation';
import {IUser, UserContext} from "../../../../hooks/Context/UserContext";
import {useQueryAdmins} from "../../../../api/admin/useQueryAdmins";
import {PermissionType} from "../../../../constants/types/PermissionType";
import Dropdown, {DropdownOption} from "../../../../components/UI/SearchAndSort/DropdownWithIcon/Sorting";
import {useAssignAdmin} from "../../../../api/admin/useAssignAdmin";
import {usePrograms} from "../../../../api/api-hooks/usePrograms";
import NavigationTabs from "../../../../components/UI/NavigationTabs/NavigationTabs";

const ApplicantProfile = () => {
  const [user] = useContext(UserContext);
  const {portfolio_id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [currPage, setCurrPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(programsSortingOptionsAdmin[4]);
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  const [assignModal, setAssignModal] = useState<boolean>(false);
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [filterValue, setFilterValue] = useState<ISortingOption[]>([]);
  const selectedFilters = filterValue.map((filter) => filter.value).join(',');
  const [activeTab, setActiveTab] = useState<'courses' | 'info'>(
    (location.state as { activeTab?: 'courses' | 'info' })?.activeTab || 'courses'
  );

  const {data, isLoading} = usePrograms({
    searchValue: searchValue,
    sortingValue: sortingValue?.value,
    filterValue: selectedFilters,
    pageState: [currPage, setCurrPage],
    portfolio_id
  });
  const {data: report, isLoading: isLoadingReport} = useQueryReport({
    portfolio_id: portfolio_id,
  });
  const {data: userInfo, isLoading: isLoadingUser, refetch} = useQueryUserInfo({
    portfolio_id: portfolio_id,
  });
  const {mutate: assignPortfolio, isSuccess: isAssignPortfolioSuccess} = useAssignPortfolio();
  const {mutate: assignPortfolioToAdmin, isSuccess: isAssignToAdminSuccess} = useAssignAdmin();
  const {mutate: reviewPortfolio} = useFinishPortfolioReview();
  const {
    data: adminsList,
    refetch: refetchAdminList
  } = useQueryAdmins();


  const getCardInfo = (program: IProgram) => {
    switch (program.status) {
      case 'review_not_started':
        return {type: PlarCardType.Not_started, chicletText: 'Review not started'};
      case 'review_in_progress':
        return {type: PlarCardType.In_progress, chicletText: 'Review in progress'};
      case 'review_completed':
        return {type: PlarCardType.Completed, chicletText: 'Review complete'};
      case 'no_status':
        return {type: PlarCardType.Not_started, chicletText: undefined};
      default:
        return {type: PlarCardType.Not_started, chicletText: 'Review not started'};
    }
  };

  const handleAssignPortfolio = () => {
    assignPortfolio({
      portfolio_id: portfolio_id ?? '',
      assign: !userInfo?.assigned_to_me,
    });
    setAssignModal(false);
  };

  const handleAssignAdmin = (admin: IUser) => {
    assignPortfolioToAdmin({
      portfolio_id: portfolio_id ?? '',
      user_id: admin.id ?? ''
    })
  }

  const handleFinishReview = () => {
    reviewPortfolio({portfolio_id});
    setReviewModal(false);
    toast.success('Portfolio review has been completed.');
    navigate('/cpl-admin');
  };

  useEffect(() => {
    refetch();
  }, [])

  useEffect(() => {
    if (isAssignPortfolioSuccess) {
      refetch();
      setPortfolio({});
    }
  }, [isAssignPortfolioSuccess]);

  useEffect(() => {
    if (triggerDownload && pdfUrl) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = pdfUrl;
      a.download = `${userInfo?.portfolio_user_name.replace(' ', '-')}_${moment().format('DD-MM-YYYY')}`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(a);
      }, 0);
      setTriggerDownload(false);
    }
  }, [triggerDownload, pdfUrl]);

  useEffect(() => {
    if (user.permission_groups?.includes(PermissionType.cpl_super_admin)) {
      refetchAdminList();
    }
  }, [user, refetchAdminList])

  useEffect(() => {
    if (isAssignToAdminSuccess) {
      refetch();
    }
  }, [isAssignToAdminSuccess])

  if (isLoading || isLoadingReport || isLoadingUser) {
    return <Loading loading/>;
  }

  return (
    <div>
      {isLoading || triggerDownload ? <Loading loading/> : null}
      <div className="applicant-profile">
        <Breadcrumbs
          content={[
            {
              label: 'Applicants',
              link: `/cpl-admin`,
            },
            {
              label: `${userInfo?.portfolio_user_name}’s profile`,
              link: `/cpl-admin/${portfolio_id}`,
            },
          ]}
        />

        {userInfo && (
          <Notification>
            {userInfo.reopened
              ? "This profile has been reopened due to changes made on the applicant side."
              : "Please assign the applicant to yourself before starting the review process."
            }
          </Notification>
        )}

        <div className="applicant-profile-header">
          <UserProfile
            name={{
              first_name: userInfo?.portfolio_user_name.split(' ')[0],
              last_name: userInfo?.portfolio_user_name.split(' ')[1],
            }}
            size="large"
            showText
          />

          <div className="applicant-profile-actions">
            <div className="main-actions">
              <ButtonKMQ
                disabled={!userInfo?.assigned_to_me || !userInfo?.can_finish}
                onClick={() => setReviewModal(true)}
              >
                Finish review
              </ButtonKMQ>
              <Info text="By clicking 'Finish Review,' no further changes can be made..."/>

              <PDFDownloadLink
                document={<ReportFile report={triggerDownload ? report : {}}/>}
                fileName={`${userInfo?.portfolio_user_name.replace(' ', '-')}_${moment().format('DD-MM-YYYY')}`}
              >
                {({url}) => {
                  if (url && pdfUrl !== url) setPdfUrl(url);
                  return (
                    <ButtonKMQ
                      onClick={() => setTriggerDownload(true)}
                      disabled={report?.programs?.length === 0}
                      type={ButtonTypes.Secondary}
                    >
                      Generate report
                    </ButtonKMQ>
                  );
                }}
              </PDFDownloadLink>
              <Info
                text={'To generate a report for the applicant\'s approved course credits, click this button. The report will contain a summary of all accepted courses approved by you for the applicant you are reviewing. Courses will be divided into their corresponding programs, with an appendix of attached evidence. You can generate the report at any time, and it will include any new courses approved since the last report'}/>

              <ButtonKMQ
                onClick={() => navigate(`/cpl-admin/${portfolio_id}/request-document-upload`)}
                type={ButtonTypes.Tertiary}
                disabled={!userInfo?.assigned_to_me && !user?.permission_groups?.includes(PermissionType.cpl_super_admin)}
              >
                Request documents
              </ButtonKMQ>
            </div>

            <div className="assignment-toggle">
              {user.permission_groups?.includes(PermissionType.cpl_super_admin)
                ? <Dropdown
                  options={adminsList
                    ? [
                      {
                        label: 'Assign to me',
                        value: {id: user.id}
                      },
                      ...adminsList.map((item: IUser) => {
                        return {
                          label: item?.first_name + ' ' + item?.last_name,
                          value: item
                        }
                      })
                    ]
                    : []}
                  active={{
                    label: `Assigned to: ${userInfo.assigned_to ? userInfo.assigned_to : 'N/A'}`,
                    value: {}
                  }
                  }
                  onChange={(option: DropdownOption) => {
                    handleAssignAdmin(option.value)
                  }}
                />
                : <Toggle
                  active={userInfo?.assigned_to_me}
                  onToggle={() => setAssignModal(true)}
                  disabled={userInfo?.assigned_to && !userInfo?.assigned_to_me}
                  label={`Assigned to: ${userInfo?.assigned_to_me ? 'Me' : userInfo?.assigned_to || 'N/A'}`}
                />
              }
            </div>
          </div>
        </div>

        <NavigationTabs
          tabs={[
            {
              name: 'Recommended courses',
              onClick: () => setActiveTab('courses'),
              active: activeTab === 'courses'
            },
            {
              name: 'Applicant\'s information',
              onClick: () => setActiveTab('info'),
              active: activeTab === 'info'
            }
          ]}
        />

        <div className="applicant-profile-content">
          {activeTab === 'courses' && (
            <div className="courses-content">
              <ComplexSearch
                filter={{
                  name: '',
                  value: filterValue,
                  options: profileFilterOptions,
                  onChange: setFilterValue
                }}
                search={{
                  value: searchValue,
                  onChange: (e) => setSearchValue(e.target.value),
                  placeholder: 'Search Programs',
                }}
                sorting={{
                  options: programsSortingOptionsAdmin,
                  value: sortingValue,
                  onChange: (newSorting) => setSortingValue(newSorting),
                }}
              />

              {data?.programs?.map((item: IProgram, index: number) => (
                <ProgramAdminCard
                  key={index}
                  {...getCardInfo(item)}
                  title={item.name}
                  subtitle={`${item.num_cpl_eligible_recommendations} out of ${item.num_courses_recommended} course${item.num_cpl_eligible_recommendations > 1 ? 's are' : ' is'} eligible`}
                  onClick={() => navigate(`/cpl-admin/${portfolio_id}/${item.id}`)}
                  buttonText="View recommended courses"
                  statusText={item.num_courses_not_reviewed === 0
                    ? [`${item.num_courses_recommended} course${item.num_courses_recommended > 1 ? 's' : ''} reviewed on ${item.reviewed_at_date} by ${item.reviewer_name}`]
                    : undefined}
                  showFavouriteAdmin={item.favourite}
                  num_cpl_eligible_recommendations={item.num_cpl_eligible_recommendations}
                  num_paid_recommendations={item.num_paid_recommendations}
                  num_courses_in_cart={item.num_courses_in_cart}
                  active_catalogue_version={item.active_catalogue_version}
                />
              ))}

              {Number(data?.total_pages) > 1 && (
                <Pagination
                  total={Number(data.total_pages)}
                  currentPage={currPage}
                  onPageChange={setCurrPage}
                />
              )}
            </div>
          )}

          {activeTab === 'info' && <ApplicantInformation data={userInfo}/>}
        </div>
        <AlertModal
          open={reviewModal}
          onClose={() => setReviewModal(false)}
          onProceed={handleFinishReview}
          title="Finish review?"
          text="Are you sure you want to finish the review of this portfolio?"
          closeText="No"
          proceedText="Yes"
        />

        <AlertModal
          open={assignModal}
          onClose={() => setAssignModal(false)}
          onProceed={handleAssignPortfolio}
          text={userInfo?.assigned_to_me
            ? 'Are you sure you want to unassign this user?'
            : 'You are assigning this user to yourself. Do you wish to continue?'
          }
          closeText="No"
          proceedText="Yes"
        />
      </div>
    </div>
  );
};

export default ApplicantProfile;
