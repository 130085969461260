import './quiz.template.scss';
import {IQuizQuestion} from "../../../../constants/types/Quiz";
import QuizQuestion from "./QuizQuestion";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAssignQuiz} from "../../../../api/quiz/useAssignQuiz";
import {toast} from "react-toastify";
import Pagination from "../../../assessments/components/EmployeeTablePagination/Pagination";
import {IDate} from "../../../../constants/resume/dates";
import moment from "moment";
import {CheckCircle} from "@phosphor-icons/react";
import AlertModal from "../../../../components/UI/Modals/AlertModal/AlertModal";

interface QuizTemplateProps {
  questions: IQuizQuestion[],
  currPage: number,
  setCurrPage: (newPage: number) => void,
  totalPages: number,
  dueDate: IDate,
  errorDate: [string, (newError: string) => void]
}

const Questions_Per_Page = 5;

const QuizTemplate = (props: QuizTemplateProps) => {
  const {
    questions,
    currPage,
    setCurrPage,
    totalPages,
    dueDate,
    errorDate
  } = props;

  const [error, setError] = errorDate;
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const {portfolio_id, program_id, course_code, quiz_id} = useParams();

  const {mutate: assignQuiz, isSuccess} = useAssignQuiz();

  const handleSubmit = () => {
    const dateCombined = moment(`${dueDate.year}-${dueDate.month}-${dueDate.day}`, 'YYYY-MMMM-DD');
    if (!dateCombined.isValid() || !dueDate.year || !dueDate.month || !dueDate.day) {
      window.scroll(0, 0);
      setError('Date is not valid.');
      return;
    }
    if (dateCombined < moment()) {
      setError('Please choose the future date.');
      window.scroll(0, 0);
      return;
    }

    setError('');
    assignQuiz({
      portfolio_id: portfolio_id ? portfolio_id : '',
      assessment_id: quiz_id ? quiz_id : '',
      due_date: dateCombined.format('YYYY-MM-DD').toString()
    });
  }

  const closeModal = () => {
    setShowModal(false);
    navigate(`/cpl-admin/${portfolio_id}/${program_id}`);
  }

  useEffect(() => {
    if (isSuccess) {
      setShowModal(true);
    }
  }, [isSuccess])

  return <div className={'draft-quiz-template'}>
    <div className={'draft-quiz-template-title'}>
      Questions
    </div>
    {
      questions.map((item: IQuizQuestion, index: number) =>
        <div key={index}>
          <QuizQuestion
            question={item}
            current_page={currPage}
            index={(currPage - 1) * Questions_Per_Page + index + 1}
          />
        </div>
      )
    }

    {
      totalPages > 1
        ? <Pagination
          total={totalPages}
          currentPage={currPage}
          onPageChange={setCurrPage}
        />
        : null
    }

    <div className={'draft-quiz-footer'}>
      <ButtonKMQ onClick={() => navigate(`/cpl-admin/${portfolio_id}/${program_id}/${course_code}`)}
                 type={ButtonTypes.Secondary}>
        Back
      </ButtonKMQ>
      <ButtonKMQ onClick={handleSubmit}>
        Confirm and send
      </ButtonKMQ>
    </div>

    <AlertModal
      open={showModal}
      onClose={closeModal}
      icon={<CheckCircle size={56} color="#212121"/>}
      text={'Your quiz request has been sent'}
      onProceed={closeModal}
      proceedText={'Return to courses list'}
    />
  </div>
}

export default QuizTemplate;