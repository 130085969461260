import "./progressLoadingBar.scss";
import {useState, useEffect} from "react";
import Modal from "react-modal";

interface ProgressProps {
  loading: boolean;
  expectedWaitTime?: number // in seconds,
  text: string
  logo: string
}

const LoadingProgressBar = (props: ProgressProps) => {
  const {
    loading,
    expectedWaitTime,
    text,
    logo
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const loadingdefaultTime = expectedWaitTime ? expectedWaitTime : 20;
  const pauseProgressBottom = 91, pauseProgressTop = 95;
  const pauseProgress = Math.floor(Math.random() * (pauseProgressTop - pauseProgressBottom) + pauseProgressBottom);

  const [progress, setProgress] = useState<number>(0);

  const incrementProgress = () => {
    setProgress(currentProgress => {
      return Math.min(currentProgress + (Math.random() + 0.4), pauseProgress);
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loading) {
      setShowModal(true);
      timer = setInterval(incrementProgress, loadingdefaultTime * 10);
      return () => clearInterval(timer);
    }
    if (progress > 0) {
      setProgress(100);
      setTimeout(() => setShowModal(false), 300);
    }

  }, [loading]);

  return <div className={'loading-progress-bar'}>
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      portalClassName={'loading-modal'}
      contentLabel="Loading Modal"
    >
      <img
        src={logo}
        alt={'logo'}
        className={'loading-modal-logo'}
      />
      <div className={'loading-modal-content'}>
        <h2 className={'loading-modal-content-text'}>
          {text}
        </h2>
        <div className={'loading-modal-content-bar'}>
          <div
            className={'loading-modal-content-bar-filled'}
            style={{width: `${progress}%`}}
          />
        </div>
        <h5 className={'loading-modal-content-progress'}>
          {Math.round(progress)}%
        </h5>
      </div>
    </Modal>
  </div>
};

export default LoadingProgressBar;
