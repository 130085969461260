import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {cplDraftQuizURL, cplQuizResultsEditURL} from "../../constants/api/quiz_endpoints";
import {useQueryClient} from "@tanstack/react-query";

interface QuizParams {
  id: string,
  body: {
    note?: string,
    score?: number
  }
}

export const usePostQuizAnnotation = ({assessment_id, portfolio_id}: {portfolio_id?: string, assessment_id?: string}) => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postQuiz(data: QuizParams) {
    return axios.post(cplQuizResultsEditURL(data.id), data.body)
      .then(response => response.data);
  }

  return useMutation({
    mutationKey: ['quiz', 'annotation'],
    mutationFn: postQuiz,
    retry: 1,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['quiz-results', assessment_id, portfolio_id]})
  });
}
