import { inactiveUsersExportUrl } from '../../constants/api/endpoints';
import { useFileDownload, BaseDownloadParams } from './useFileDownload';

export interface InactiveUsersExportParams extends BaseDownloadParams {
  start_date?: string;
  end_date?: string;
  filter_action_type?: any;
  filter_date_type?: string;
  search_term?: string;
}

export const useInactiveUsersExport = () => {
  const { downloadFile } = useFileDownload();

  const downloadInactiveUsers = async (params: InactiveUsersExportParams) => {
    return downloadFile(inactiveUsersExportUrl, params);
  };

  return { downloadInactiveUsers };
};