import AdminCourses from "../app/admin/courses/AdminCourses";
import EvidencesPage from "../app/admin/evidences/EvidencesPage";
import RejectDetails from "../app/admin/rejects-details/reject-details";
import FAQ from "../app/faq/FAQ";
import ApplicantsList from "../app/admin/applicants/ApplicantsList";
import ApplicantProfile from "../app/admin/applicants/[user_id]/ApplicantProfile";
import CplAdminPortfolioLayout from "../components/Layout/PermissionsLayouts/CplAdminPortfolioLayout";
import MyProfile from "../app/admin/my-profile";
import AdminDocumentRequest from "../app/admin/AdminDocumentRequest/AdminDocumentRequest";
import CreateAdmin from "../app/admin/create-admin";
import AssessmentsSuperAdminLayout from "../components/Layout/PermissionsLayouts/AssessmentsSuperAdminLayout";
import DraftQuiz from "../app/admin/quiz/draft-quiz";
import ApplicantCourseDetails
  from "../app/cpl-user/user-programs/program-id/user-courses/course-id/ApplicantCourseDetails";
import QuizCourseDetails from "../app/admin/quiz/course-details/quizCourseDetails";
import QuizResults from "../app/admin/quiz/results";

export const cplAdmin = [
  {
    path: '/cpl-admin',
    element: <ApplicantsList/>
  },
  {
    path: '/cpl-admin/:portfolio_id',
    element: <ApplicantProfile/>
  },
  {
    path: '/cpl-admin/:portfolio_id/request-document-upload',
    element: <AdminDocumentRequest/>
  },
  {
    path: '/cpl-admin/:portfolio_id/:program_id',
    element: <CplAdminPortfolioLayout/>,
    children: [
      {
        path: '/cpl-admin/:portfolio_id/:program_id',
        element: <AdminCourses/>
      },
      {
        path: '/cpl-admin/:portfolio_id/:program_id/:course_code',
        element: <EvidencesPage/>
      },
      {
        path: '/cpl-admin/:portfolio_id/:program_id/:course_code/quiz/:quiz_id',
        element: <DraftQuiz/>
      },
      {
        path: '/cpl-admin/:portfolio_id/:program_id/:course_code/quiz/:quiz_id/results',
        element: <QuizResults/>
      },
      {
        path: '/cpl-admin/:portfolio_id/:program_id/:course_code/quiz/:quiz_id/course-details',
        element: <QuizCourseDetails/>,
        children: [
          {
            path: '/cpl-admin/:portfolio_id/:program_id/:course_code/quiz/:quiz_id/course-details',
            element: <ApplicantCourseDetails/>,
          }
        ]
      },
      {
        path: '/cpl-admin/:portfolio_id/:program_id/:course_code/reject-reason',
        element: <RejectDetails/>
      },
    ]
  },
  {
    path: '/cpl-admin/faq',
    element: <FAQ/>
  },
  {
    path: '/cpl-admin/my-profile',
    element: <MyProfile/>
  },
  {
    path: '/cpl-admin/super-admin',
    element: <AssessmentsSuperAdminLayout/>,
    children: [
      {
        path: '/cpl-admin/super-admin/create-admin',
        element: <CreateAdmin/>
      }
    ]
  }
]