import './benefits.scss';
import {ILandingData} from "../../../../../../constants/types/LandingData";
import {NavBarOptions} from "../../../../../../components/UI-v2/NavBar";

const Benefits = ({landingPageInfo}: { landingPageInfo: ILandingData }) => {

  const benefitsList = [
    {
      title: 'Reduce your tuition costs',
      desc: 'Receiving credits for the skills you already have means fewer courses to pay for, reducing the financial pressure of going back to school.',
      subtitle: '$1,500-$10,200 tuition savings',
      subtitleDesc: `Adult students who earned ${landingPageInfo.cpl_term} saved $1,500-$10,200 in tuition dollars on average.`
    },
    {
      title: 'Reduce your time spent in class',
      desc: `Every credit you earn through ${landingPageInfo.cpl_term} directly reduces the number of classes you need to take, saving you time and making it easier to balance school with other commitments, like family and work.`,
      subtitle: '9-17 months faster graduation time',
      subtitleDesc: `Adult learners who earn ${landingPageInfo.cpl_term} credits reduce their academic journey by between 9-17 months.`
    },
    {
      title: 'Graduate. Faster.',
      desc: 'With fewer courses to complete, there’s less standing in your way of graduation. Finish your postsecondary journey faster and start your new career sooner.',
      subtitle: '2.5x greater likelihood of graduating',
      subtitleDesc: `Learners who earn more than two ${landingPageInfo.cpl_term} credits have a 2.5x greater graduation rate than direct applicants to academic programs.`
    },
  ]

  return <div className={'benefits'} id={NavBarOptions.benefits}>
    <div className={'text-label benefits-title'}>
      Benefits of {landingPageInfo.cpl_term_full} ({landingPageInfo.cpl_term})
    </div>

    <ol className={'benefits-list'}>
      {
        benefitsList.map((item, index: number) =>
          <li key={index}>
            <h3 className={'benefits-list-title'}>
              {item.title}
            </h3>
            <div className={'text-body-large benefits-list-desc'}>
              {item.desc}
            </div>
            <div className={'benefits-list-body'}>
              <h5 className={'benefits-list-body-subtitle'}>
                {item.subtitle}
              </h5>
              <div className={'text-body-small benefits-list-body-text'}>
                {item.subtitleDesc}
              </div>
            </div>
          </li>)
      }
    </ol>
  </div>
}

export default Benefits;