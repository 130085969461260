import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {assignQuizURL} from "../../constants/api/quiz_endpoints";

interface QuizParams {
  'assessment_id': string,
  'portfolio_id': string,
  'due_date': string
}

export const useAssignQuiz = () => {
  const axios = useAxios();

  async function postQuiz(data: QuizParams) {
    return axios.post(assignQuizURL, data)
      .then(response => response.data);
  }

  return useMutation({
    mutationKey: ['quiz', 'assign'],
    mutationFn: postQuiz,
  });
}
