import './need-help.scss';
import {ILandingData} from "../../../../../../constants/types/LandingData";
import ChatIcon from '../../../../../../assets/v2/icons/chat.svg';
import QuestionIcon from '../../../../../../assets/v2/icons/questionMark.svg';

const NeedHelp = ({landingPageInfo}: { landingPageInfo: ILandingData }) => {
  return <div className={'need-help'}>
    <h2 className={'need-help-title'}>
      Need help?
    </h2>
    <div className={'text-body-small need-help-desc'}>
      If you have questions about {landingPageInfo.cpl_term} or need help with your application, we’re here to support
      you.
    </div>
    <div className={'need-help-body'}>
      <div className={'need-help-body-item'}>
        <h4 className={'need-help-body-item-title'}>
          <img
            src={ChatIcon}
            alt={'chat'}
          />
          {landingPageInfo.cpl_term} advice & support
        </h4>
        <div
          className={'text-body-small need-help-body-item-desc'}
          dangerouslySetInnerHTML={{__html: landingPageInfo.plar_support_html}}
        />
      </div>
      <div className={'need-help-body-item'}>
        <h4 className={'need-help-body-item-title'}>
          <img
            src={QuestionIcon}
            alt={'question mark'}
          />
          Technical support
        </h4>
        <div
          className={'text-body-small need-help-body-item-desc'}
          dangerouslySetInnerHTML={{__html: landingPageInfo.technical_support_html}}
        />
      </div>
    </div>
  </div>
}

export default NeedHelp;