import React, {useState, useContext, useMemo} from "react";
import {useParams} from "react-router-dom";
import "./quiz-page.scss";
import {useQueryQuiz} from "../../../api/cpl-applicant/useQueryQuiz";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {useQueryCourses} from "../../../api/admin/useQueryCourses";
import {ICourse} from "../../admin/courses/AdminCourses";
import QuizIntro from "./QuizIntro";
import QuizPage from "./QuizPage";

const QuizApplicant = () => {
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const {program_id, course_code} = useParams();
  const [portfolio] = useContext(PortfolioContext);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 5;

  const {data: courses} = useQueryCourses({
    portfolio_id: portfolio.id,
    program_id: program_id,
    course_code: course_code,
  });

  const currCourse = useMemo(() => {
    if (courses && courses?.data?.courses?.length > 0) {
      return courses?.data?.courses.find((item: ICourse) => item.code === course_code)
    }
  }, [course_code, courses])

  const {
    data: quizData,
    isLoading,
    isError,
    refetch,
  } = useQueryQuiz({
    portfolio_id: portfolio.id ? portfolio.id : '',
    course_id: currCourse?.id ? currCourse.id : '',
    items_per_page: questionsPerPage,
    current_page: currentPage,
  });

  if (isLoading) {
    return <div>Loading quiz questions...</div>;
  }

  if (quizData) {
    if (showIntro && !quizData.quiz_request?.completed) {
      return <QuizIntro
        program_id={program_id}
        quiz={quizData}
        closeIntro={() => {
          setShowIntro(false);
          window.scroll(0, 0);
        }}
      />
    }
    return <QuizPage
      program_id={program_id}
      quizData={quizData}
      refetch={refetch}
      questionsPerPage={questionsPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  }

  if (isError) {
    return <div>Error loading quiz questions. Please try again.</div>;
  }

  return <div>Loading quiz questions...</div>;
};

export default QuizApplicant;