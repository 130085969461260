import React, { useState, useCallback, useRef, useEffect } from "react";
import moment, { Moment } from "moment";
import SortingIcon from "../../../assets/icons/UI/icons/sort.svg";
import OpenIcon from "../../../assets/icons/UI/select/arrow-down.svg";
import CloseIcon from "../../../assets/icons/UI/select/arrow-up.svg";
import {
	CaretRight,
	ArrowCircleLeft,
	ArrowCircleRight,
} from "@phosphor-icons/react";
import "./date-range-filter.scss";
import ButtonKMQ from "../Button/ButtonKMQ";
import { ButtonTypes } from "../../../constants/ui/types/ButtonTypes";

type InteractionType = "first" | "last";

interface DateRange {
	startDate: Moment | null;
	endDate: Moment | null;
}

interface DateRangeValue {
	firstInteraction: DateRange;
	lastInteraction: DateRange;
	activeInteraction: InteractionType | null;
}

interface DateRangeFilterProps {
	value: DateRangeValue;
	onChange: (value: DateRangeValue) => void;
	minDate?: Moment;
	maxDate?: Moment;
	className?: string;
	onClose?: () => void;
	disabledDates?: Moment[];
}

type InteractionMap = {
	first: "firstInteraction";
	last: "lastInteraction";
};

const interactionToKey: InteractionMap = {
	first: "firstInteraction",
	last: "lastInteraction",
};

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
	value,
	onChange,
	minDate,
	maxDate,
	className = "",
	onClose,
	disabledDates = [],
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const [currentMonth, setCurrentMonth] = useState<Moment>(
		moment().subtract(1, "month")
	);
	const [tempSelection, setTempSelection] = useState<DateRangeValue>(value);
	const [dateSelectionStep, setDateSelectionStep] = useState<"start" | "end">(
		"start"
	);

	const filterRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				filterRef.current &&
				!filterRef.current.contains(event.target as Node)
			) {
				setIsDropdownOpen(false);
				setShowCalendar(false);
			}
		};

		if (isDropdownOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isDropdownOpen]);

	const toggleDropdown = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDropdownOpen(!isDropdownOpen);
	};

	const isDateDisabled = useCallback(
		(date: Moment): boolean => {
			if (minDate && date.isBefore(minDate, "day")) return true;
			if (maxDate && date.isAfter(maxDate, "day")) return true;
			return disabledDates.some((disabledDate) =>
				date.isSame(disabledDate, "day")
			);
		},
		[minDate, maxDate, disabledDates]
	);

	const resetOtherInteraction = (currentInteraction: InteractionType) => {
		const otherInteraction =
			currentInteraction === "first" ? "lastInteraction" : "firstInteraction";
		return {
			...tempSelection,
			[otherInteraction]: { startDate: null, endDate: null },
		};
	};

	const handleDayClick = (date: Moment) => {
		if (isDateDisabled(date) || !tempSelection.activeInteraction) return;

		const interactionKey = interactionToKey[tempSelection.activeInteraction];
		const currentRange = tempSelection[interactionKey];

		if (dateSelectionStep === "start") {
			setTempSelection({
				...resetOtherInteraction(tempSelection.activeInteraction),
				[interactionKey]: {
					startDate: date,
					endDate: null,
				},
			});
			setDateSelectionStep("end");
		} else {
			if (currentRange.startDate && date.isBefore(currentRange.startDate)) {
				setTempSelection({
					...resetOtherInteraction(tempSelection.activeInteraction),
					[interactionKey]: {
						startDate: date,
						endDate: currentRange.startDate,
					},
				});
			} else {
				setTempSelection({
					...resetOtherInteraction(tempSelection.activeInteraction),
					[interactionKey]: {
						...currentRange,
						endDate: date,
					},
				});
			}
			setDateSelectionStep("start");
		}
	};

	const generateCalendarDays = useCallback(
		(
			month: Moment
		): Array<{
			date: Moment;
			isCurrentMonth: boolean;
			isToday: boolean;
			isDisabled: boolean;
		}> => {
			const days = [];
			const firstDay = month.clone().startOf("month");
			const lastDay = month.clone().endOf("month");

			const firstDayWeekday = firstDay.day();
			for (let i = firstDayWeekday - 1; i >= 0; i--) {
				const date = firstDay.clone().subtract(i + 1, "days");
				days.push({
					date,
					isCurrentMonth: false,
					isToday: date.isSame(moment(), "day"),
					isDisabled: isDateDisabled(date),
				});
			}

			let currentDate = firstDay.clone();
			while (currentDate.isSameOrBefore(lastDay)) {
				days.push({
					date: currentDate.clone(),
					isCurrentMonth: true,
					isToday: currentDate.isSame(moment(), "day"),
					isDisabled: isDateDisabled(currentDate),
				});
				currentDate.add(1, "day");
			}

			const remainingDays = 42 - days.length;
			currentDate = lastDay.clone().add(1, "day");
			for (let i = 0; i < remainingDays; i++) {
				days.push({
					date: currentDate.clone(),
					isCurrentMonth: false,
					isToday: currentDate.isSame(moment(), "day"),
					isDisabled: isDateDisabled(currentDate),
				});
				currentDate.add(1, "day");
			}

			return days;
		},
		[isDateDisabled]
	);

	const isDateInRange = (date: Moment): boolean => {
		if (!tempSelection.activeInteraction) return false;
		const interactionKey = interactionToKey[tempSelection.activeInteraction];
		const currentRange = tempSelection[interactionKey];
		if (!currentRange.startDate || !currentRange.endDate) return false;
		return date.isBetween(
			currentRange.startDate,
			currentRange.endDate,
			"day",
			"[]"
		);
	};

	const handleMonthNavigation = (direction: "prev" | "next") => {
		const newMonth = currentMonth
			.clone()
			.add(direction === "next" ? 1 : -1, "month");
		setCurrentMonth(newMonth);
	};

	const handleInteractionClick = (type: InteractionType) => {
		setTempSelection({
			...tempSelection,
			activeInteraction: type,
		});
		setDateSelectionStep("start");
		setShowCalendar(true);
	};

	const handleSelect = () => {
		onChange(tempSelection);
		setShowCalendar(false);
		setIsDropdownOpen(false);
		onClose?.();
	};

	const handleCancel = () => {
		const resetSelection = {
			firstInteraction: { startDate: null, endDate: null },
			lastInteraction: { startDate: null, endDate: null },
			activeInteraction: null,
		};
		setTempSelection(resetSelection);
		onChange(resetSelection);
		setShowCalendar(false);
		setIsDropdownOpen(false);
		onClose?.();
	};

	const renderCalendarMonth = (monthOffset: number = 0) => {
		const calendarMonth = currentMonth.clone().add(monthOffset, "month");
		const days = generateCalendarDays(calendarMonth);
		const currentRange = tempSelection.activeInteraction
			? tempSelection[interactionToKey[tempSelection.activeInteraction]]
			: null;

		return (
			<div className="calendar">
				<div className="calendar-header">
					{monthOffset === 0 ? (
						<button
							className="calendar-nav-btn"
							onClick={() => handleMonthNavigation("prev")}
						>
							<ArrowCircleLeft size={16} weight="bold" />
						</button>
					) : (
						<div />
					)}
					<span className="calendar-month">
						{calendarMonth.format("MMMM YYYY")}
					</span>
					{monthOffset === 1 && (
						<button
							className="calendar-nav-btn"
							onClick={() => handleMonthNavigation("next")}
						>
							<ArrowCircleRight size={16} weight="bold" />
						</button>
					)}
				</div>
				<div className="calendar-grid">
					<div className="weekdays">
						{moment.weekdaysShort().map((day) => (
							<div key={day} className="weekday">
								{day}
							</div>
						))}
					</div>
					<div className="days">
						{days.map((day, index) => (
							<div
								key={index}
								className={`day 
                  ${day.isCurrentMonth ? "current-month" : "other-month"}
                  ${day.isToday ? "today" : ""}
                  ${day.isDisabled ? "disabled" : ""}
                  ${
										currentRange?.startDate?.isSame(day.date, "day")
											? "selected-start"
											: ""
									}
                  ${
										currentRange?.endDate?.isSame(day.date, "day")
											? "selected-end"
											: ""
									}
                  ${isDateInRange(day.date) ? "in-range" : ""}`}
								onClick={() => handleDayClick(day.date)}
							>
								{day.date.format("D")}
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className={`date-range-filter ${className}`} ref={filterRef}>
			<button className="filter-button" onClick={toggleDropdown}>
				<img src={SortingIcon} alt="sorting" className="sorting-active-icon" />
				<span>Filter by: Dates</span>
				<img
					src={isDropdownOpen ? CloseIcon : OpenIcon}
					alt={isDropdownOpen ? "close-menu" : "open-menu"}
					className="sorting-active-open-btn"
				/>
			</button>

			{isDropdownOpen && (
				<div className="dropdown-container">
					<div className="interaction-dropdown">
						<div
							className={`interaction-option ${
								tempSelection.activeInteraction === "first" ? "active" : ""
							}`}
							onClick={() => handleInteractionClick("first")}
						>
							<span>First interaction</span>
							<CaretRight size={16} weight="bold" />
						</div>
						<div
							className={`interaction-option ${
								tempSelection.activeInteraction === "last" ? "active" : ""
							}`}
							onClick={() => handleInteractionClick("last")}
						>
							<span>Last interaction</span>
							<CaretRight size={16} weight="bold" />
						</div>
					</div>

					{showCalendar && (
						<div className="calendar-dropdown">
							<div className="calendars-container">
								{renderCalendarMonth(0)}
								{renderCalendarMonth(1)}
							</div>
							<div className="calendar-actions">
								<ButtonKMQ type={ButtonTypes.Secondary} onClick={handleCancel}>
									Cancel
								</ButtonKMQ>
								<ButtonKMQ
									type={ButtonTypes.Primary}
									onClick={handleSelect}
									disabled={
										!tempSelection.activeInteraction ||
										!tempSelection[
											interactionToKey[tempSelection.activeInteraction]
										].startDate ||
										!tempSelection[
											interactionToKey[tempSelection.activeInteraction]
										].endDate
									}
								>
									Select
								</ButtonKMQ>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default DateRangeFilter;
