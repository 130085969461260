import './quiz.question.scss';
import {IQuizQuestion} from "../../../../../constants/types/Quiz";
import {PencilSimple, Trash} from "@phosphor-icons/react";
import {useEffect, useState} from "react";
import AlertModal from "../../../../../components/UI/Modals/AlertModal/AlertModal";
import ButtonKMQ from "../../../../../components/UI/Button/ButtonKMQ";
import {useDeleteQuizQuestion} from "../../../../../api/quiz/useDeleteQuizQuestion";
import {useEditQuizQuestion} from "../../../../../api/quiz/useEditQuizQuestion";
import {useParams} from "react-router-dom";

const QuizQuestion = ({question, current_page, index}: { question: IQuizQuestion, current_page: number, index: number }) => {
  const [edited, setEdited] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [newText, setNewText] = useState<string>('');
  const {quiz_id} = useParams();

  const {
    mutate: editQuestion
  } = useEditQuizQuestion({
    quiz_id: quiz_id ? quiz_id : '',
    current_page
  });
  const {
    mutate: deleteQuestion
  } = useDeleteQuizQuestion({
    quiz_id: quiz_id ? quiz_id : '',
    current_page
  });

  const handleDeleteQuestion = () => {
    deleteQuestion({question_id: question.question_id});
    setDeleteModal(false);
  }

  const handleEditQuestion = () => {
    editQuestion({
      question_id: question.question_id,
      question: {
        ...question,
        question_text: newText
      }
    })
    setEdited(false);
  }

  useEffect(() => {
    if (question.question_text) {
      setNewText(question.question_text);
    }
  }, [question])

  return <div className={'draft-quiz-question'}>
    <div className={'draft-quiz-question-title'}>
      {question.learning_outcome.sentence}
    </div>
    <div className={'draft-quiz-question-body'}>
      <div className={'draft-quiz-question-body-text'}>
        {
          edited
            ? <div className={'draft-quiz-question-body-text-edit'}>
              <textarea
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                  rows={4}
                />
              <ButtonKMQ onClick={handleEditQuestion}>
                Save
              </ButtonKMQ>
            </div>
            : <span>
              {index}. {question.question_text}
            </span>
        }
      </div>
      <span className={'draft-quiz-question-body-icons'}>
        <PencilSimple
          size={20}
          color={'#212121'}
          onClick={() => setEdited(!edited)}
        />
        <Trash
          size={20}
          color={'#212121'}
          onClick={() => setDeleteModal(true)}
        />
      </span>
    </div>

    <AlertModal
      open={deleteModal}
      onClose={() => setDeleteModal(false)}
      proceedText={'Yes'}
      closeText={'Back'}
      text={'Are you sure you want to delete the current question?'}
      onProceed={handleDeleteQuestion}
    />
  </div>
}

export default QuizQuestion;