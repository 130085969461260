import React, {useState, ChangeEvent, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import TextArea from "../../../components/UI/TextArea/TextArea";
import Pagination from "../../assessments/components/EmployeeTablePagination/Pagination";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import {WarningCircle, CheckCircle} from "@phosphor-icons/react";
import Back from "../../../components/UI/Back/Back";
import "./quiz-page.scss";
import {useMutateQuizQuestionAnswer} from "../../../api/cpl-applicant/useMutateQuizQuestionAnswer";
import {APIQuestion, Question, QuizResponse} from "../../../constants/types/QuizTypes";
import {useMutateCompleteQuiz} from "../../../api/cpl-applicant/useMutateCompleteQuiz";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import SaveIcon from "../../../components/UI/CustomIcons/SaveIcon/SaveIcon";

interface QuizPageProps {
 program_id?: string,
 quizData: QuizResponse,
 refetch: () => void,
 questionsPerPage: number,
 currentPage: number,
 setCurrentPage: (page: number) => void
}

const QuizPage = (props: QuizPageProps) => {
 const {
   program_id,
   quizData,
   refetch,
   questionsPerPage,
   currentPage,
   setCurrentPage
 } = props;

 const navigate = useNavigate();
 const location = useLocation();
 const [submitting, setSubmitting] = useState(false);
 const [saving, setSaving] = useState(false);
 const [showSubmitConfirmAlert, setShowSubmitConfirmAlert] = useState(false);
 const [showSuccessAlert, setShowSuccessAlert] = useState(false);
 const [isSubmitted, setIsSubmitted] = useState(false);
 const [isReviewMode, setIsReviewMode] = useState(false);
 const [isInitialSave, setIsInitialSave] = useState(true);

 const submitAnswerMutation = useMutateQuizQuestionAnswer();
 const completeQuizMutation = useMutateCompleteQuiz();

 useEffect(() => {
   if (location.state?.isReviewMode || quizData?.quiz_request?.completed) {
     setIsReviewMode(true);
     setIsSubmitted(true);
   }
 }, [location.state, quizData?.quiz_request?.completed]);

 const transformQuestions = (apiQuestions: APIQuestion[]): Question[] => {
   return apiQuestions.map((q, index) => ({
     id: (currentPage - 1) * questionsPerPage + index + 1,
     text: q.question_text,
     answer: q.user_response?.answer_text || "",
     originalId: q.question_id,
     answerMetadata: q.user_response
       ? {
         responseId: q.user_response.id,
         lastUpdated: q.user_response.updated_at,
       }
       : undefined,
   }));
 };

 const [questions, setQuestions] = useState<Question[]>([]);

 useEffect(() => {
   if (quizData?.questions) {
     setQuestions(transformQuestions(quizData.questions));
     
     if (quizData.quiz_request?.completed) {
       setIsSubmitted(true);
       setIsReviewMode(true);
     }
   }
 }, [quizData, currentPage]);

 useEffect(() => {
   refetch();
   window.scroll(0, 0);
 }, [currentPage, refetch]);

 useEffect(() => {
   if (submitAnswerMutation.isError) {
     console.error("Error saving answer:", submitAnswerMutation.error);
   }
 }, [submitAnswerMutation.isError]);

 useEffect(() => {
   if (completeQuizMutation.isError) {
     console.error("Error completing quiz:", completeQuizMutation.error);
   }
 }, [completeQuizMutation.isError]);

 const handlePageChange = (newPage: number) => {
   setCurrentPage(newPage);
 };

 const areAllQuestionsAnswered = questions.every(
   (q) => q.answer.trim().length > 0
 );

 const handleAnswerChange = (id: number, value: string) => {
   if (isSubmitted || isReviewMode || quizData?.quiz_request?.completed) return;
   const updatedQuestions = questions.map((q) =>
     q.id === id ? {...q, answer: value} : q
   );
   setQuestions(updatedQuestions);
 };

 const handleFocusOut = async (questionId: number) => {
   if (isSubmitted || isReviewMode || quizData?.quiz_request?.completed) return;
   setSaving(true);
   setIsInitialSave(false);
   try {
     const currentQuestion = questions.find((q) => q.id === questionId);

     if (currentQuestion && quizData?.quiz_request?.portfolio_id) {
       await submitAnswerMutation.mutateAsync({
         question_id: currentQuestion.originalId,
         answer_text: currentQuestion.answer,
         portfolio_id: quizData.quiz_request.portfolio_id,
       });

       await refetch();
     }
   } catch (error) {
     console.error("Error auto-saving:", error);
   } finally {
     setSaving(false);
   }
 };

 const handleSubmit = () => {
   if (quizData?.quiz_request?.completed) return;
   setShowSubmitConfirmAlert(true);
 };

 const handleConfirmedSubmit = async () => {
   setShowSubmitConfirmAlert(false);
   setSubmitting(true);
   try {
     const submitPromises = questions.map((question) =>
       submitAnswerMutation.mutateAsync({
         question_id: question.originalId,
         answer_text: question.answer,
         portfolio_id: quizData?.quiz_request?.portfolio_id || "",
       })
     );

     await Promise.all(submitPromises);

     if (quizData?.assessment_id && quizData?.quiz_request?.portfolio_id) {
       await completeQuizMutation.mutateAsync({
         assessment_id: quizData.assessment_id,
         portfolio_id: quizData.quiz_request.portfolio_id,
       });
       setIsSubmitted(true);
       setShowSuccessAlert(true);
     }
   } catch (error) {
     console.error("Error submitting quiz:", error);
   } finally {
     setSubmitting(false);
   }
 };

 const handleReviewQuiz = () => {
   setShowSuccessAlert(false);
   setIsReviewMode(true);
 };

 return (
   <div className="quiz-container">
     <div className="quiz-header">
       <Back
         text={`Back to ${quizData?.course?.title || "Course"}`}
         onClick={() => navigate(`/cpl-applicant/my-programs/${program_id}`)}
       />
     </div>

     <div className="quiz-content">
       <div className="quiz-title">
         {"Quiz"}
         {(isSubmitted || quizData?.quiz_request?.completed) && " - Submitted"}
       </div>
       <div className="quiz-info">
         <h2>Course: {quizData?.course?.title}</h2>
         <h2>Due date: {quizData?.quiz_request?.due_date}</h2>
       </div>

       <p className="quiz-description">Your answers will be saved automatically as you work through the quiz.</p>

       <div className="quiz-section">
         <div className="questions-header">
           <div className="questions-title">Questions</div>
            {!isSubmitted && !isReviewMode && (
              <SaveIcon saving={saving} isInitial={isInitialSave}/>
           )}
         </div>

         <div className="questions-list">
           {questions.map((question) => (
             <div
               key={question.originalId}
               className="question-item"
               onBlur={() => handleFocusOut(question.id)}
             >
               <div className="question-title">
                  <div className="question-number">{question.id}.</div>
                  <div>{question.text}</div>
               </div>
               <TextArea
                 value={question.answer}
                 onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                   handleAnswerChange(question.id, e.target.value)
                 }
                 placeholder="Enter your answer here..."
                 disabled={isSubmitted || isReviewMode || quizData?.quiz_request?.completed}
                 error={
                   question.answer.length > 800
                     ? "Answer exceeds 800 characters limit"
                     : undefined
                 }
               />
               <div className="word-count">{question.answer.length}/800</div>
             </div>
           ))}
         </div>

         <div className="quiz-footer">
           {quizData?.total_pages > 1 && (
             <Pagination
               total={quizData?.total_pages || 1}
               currentPage={currentPage}
               onPageChange={handlePageChange}
             />
           )}

           {!isSubmitted && !isReviewMode && !quizData?.quiz_request?.completed && (
             <ButtonKMQ
               className="submit-button"
               onClick={handleSubmit}
               disabled={!areAllQuestionsAnswered || submitting}
               type={ButtonTypes.Primary}
             >
               {submitting ? "Submitting..." : "Submit"}
             </ButtonKMQ>
           )}
         </div>
       </div>
     </div>

     <AlertModal
       open={showSubmitConfirmAlert}
       icon={<WarningCircle size={56} color="#212121" />}
       onClose={() => setShowSubmitConfirmAlert(false)}
       title="Are you sure you want to submit?"
       text="Please review your answers before you submit."
       onProceed={handleConfirmedSubmit}
       proceedText="Submit"
       closeText="Review Answers"
     />

     <AlertModal
       open={showSuccessAlert}
       icon={<CheckCircle size={56} color="#212121" />}
       onClose={handleReviewQuiz}
       title="Congratulations!"
       text="Your quiz has been submitted!"
       onProceed={() => {
         setShowSuccessAlert(false);
         navigate("/cpl-applicant/my-programs");
       }}
       proceedText="Continue to My Courses"
       closeText="Review Quiz"
     />
   </div>
 );
};

export default QuizPage;