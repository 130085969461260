import Checkmark from "../../../../../../assets/v2/icons/checkmark.svg";
import React from "react";

const UploadRules = () => {
  return <div className={'upload-resume-box-rules caption-small'}>
    <img src={Checkmark} alt={'checkmark'}/>
    <span className={'upload-resume-box-rules-item caption-small'}>
      .pdf, .doc, .docx
    </span>
    <img src={Checkmark} alt={'checkmark'}/>
    <span className={'upload-resume-box-rules-item caption-small'}>
      {'<10 mb'}
    </span>
    <img src={Checkmark} alt={'checkmark'}/>
    <span className={'upload-resume-box-rules-item caption-small'}>
      1 file max
    </span>
  </div>
}

export default UploadRules;