import './footer-v2.scss';
import PrivacyPolicy from "../../Router/TextWithLink/PrivacyPolicy";
import TermsOfUse from "../../Router/TextWithLink/TermsOfUse";
import moment from "moment";

const Footer = () => {
  return <div className={'footer-v2 caption'}>
    <div className={'footer-v2-col'}>
      © KnowMeQ {moment().format('YYYY').toString()}
    </div>
    <div className={'footer-v2-col'}>
      <PrivacyPolicy/>
      <TermsOfUse text={'Terms & Conditions'}/>
    </div>
  </div>
}

export default Footer;