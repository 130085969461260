import './applicant-courses.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {ISortingOption} from "../../../../../components/UI/SearchAndSort/SearchAndSort";
import {coursesSortingOptions} from "../../../../../constants/ui/sortingOptions";
import {useQueryCourses} from "../../../../../api/admin/useQueryCourses";
import {PlarCardType} from "../../../../../components/UI/Cards/Plar/PlarCard";
import {ICourse} from "../../../../admin/courses/AdminCourses";
import {useQueryClient} from "@tanstack/react-query";
import {testing_institution_id} from "../../../../../constants/api/institution_id";
import {PortfolioContext} from "../../../../../hooks/Context/PortfolioContext";
import {useAddToCart} from "../../../../../api/payment/useAddToCart";
import CourseCard from "../../../../../components/UI/Cards/Plar/CourseCard";
import Toggle from "../../../../../components/UI/Toggle/Toggle";
import {UserContext} from "../../../../../hooks/Context/UserContext";
import ComplexSearch from "../../../../../components/UI/ComplexSearch/ComplexSearch";

const ApplicantCourses = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {program_id} = useParams();
  const queryClient = useQueryClient();
  const [portfolio] = useContext(PortfolioContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(coursesSortingOptions[2]);
  const [hidePaid, setHidePaid] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);

  const {mutate: addToCart} = useAddToCart({
    userIsSoft: user.isSoft
  });

  const handleAddToCart = (course_id: string, payment_status: string) => {
    if (payment_status === "not-selected-for-payment" ||
      payment_status === "selected-for-payment") {
      addToCart({
        course_recommendation_id: course_id,
        select_for_payment: !(payment_status === "selected-for-payment")
      })
    }
  }

  const {data: courses} = useQueryCourses({
    portfolio_id: portfolio.id,
    program_id: program_id,
    institution_id: testing_institution_id,
    searchValue,
    sortingValue: sortingValue.value,
    paid: hidePaid ? 'false' : undefined,
    userIsSoft: user.isSoft
  });

  const getCardInfo = (course: ICourse) => {
    if (course.approved === null) {
      return {
        type: PlarCardType.Not_started,
        chicletText: undefined,
        statusText: undefined
      };
    }
    if (course.approved) {
      return {
        type: PlarCardType.Completed,
        chicletText: 'Approved',
        statusText: [`Approved on ${course.reviewed_at_date} by ${course.reviewer_name}`]
      };
    }
    return {
      type: PlarCardType.Rejected,
      chicletText: 'Rejected',
      statusText: [`Rejected on ${course.reviewed_at_date} by ${course.reviewer_name}`]
    };
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  useEffect(() => {
    if (searchValue.length > 2 || searchValue.length === 0) {
      queryClient.invalidateQueries({queryKey: ['courses']});
    }
  }, [searchValue])

  useEffect(() => {
    if (init) {
      queryClient.invalidateQueries({queryKey: ['courses']});
    } else {
      setInit(true);
    }
  }, [sortingValue, hidePaid])

  return <div className={'applicant-courses'}>
    <div>
      <div className={'applicant-courses-hide-paid'}>
        <Toggle
          active={!hidePaid}
          onToggle={() => setHidePaid(!hidePaid)}
          label={'Show previously submitted courses'}
        />
      </div>

      <ComplexSearch
        search={{
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value),
          placeholder: 'Search Programs'
        }}
        sorting={{
          value: sortingValue,
          onChange: (newSorting) => setSortingValue(newSorting),
          options: coursesSortingOptions
        }}
      />

      {
        courses?.data?.courses && courses.data.courses.map((item: ICourse, index: number) =>
          <div key={index}>
            <CourseCard
              {...getCardInfo(item)}
              title={item.title}
              code={item.code}
              onClick={() => navigate(`/cpl-applicant/my-programs/${program_id}/${item.code}`)}
              buttonText={'View course details'}
              match={`${Math.floor(item.matching_score * 100)}% match`}
              cpl_eligible={item.cpl_eligible}
              payment_status={item.payment_status}
              changePaymentStatus={() => handleAddToCart(item.course_recommendation_id, item.payment_status)}
              hasQuiz={!!item?.quiz_request?.id && !item?.quiz_request.completed}
              quizDue={
                !item?.quiz_request?.completed
                  ? item.quiz_request?.due_date
                  : undefined
              }
              viewQuiz={() => navigate(location.pathname + `/${item.code}/quiz`)}
              quiz_submitted={item?.quiz_request?.completed}
              active_catalogue_version={item?.active_catalogue_version}
            />
          </div>)
      }
    </div>
  </div>
}

export default ApplicantCourses;