import './score.scss';
import InputKMQ from "../Input/InputKMQ";

interface ScoreProps {
  score: number,
  edit?: {
    canEdit: boolean,
    onEditSubmit: (newScore: number) => void
  }
}

const Score = (props: ScoreProps) => {
  const {
    score,
    edit
  } = props;

  return <div className={'score'}
              style={{
                backgroundImage: `conic-gradient(#212121 ${score}%, #FFFFFF ${score}%)`
              }}>
    <div className={'score-inner'}>
      {
        (edit && edit?.canEdit)
          ? <span className={'score-inner-edit'}>
              <InputKMQ
                value={String(score)}
                onChange={(e) => edit.onEditSubmit(Number(e.target.value))}
                type={'number'}
                placeholder={'Score'}
              />
              <span className={'score-inner-edit-percent'}>
                %
              </span>
            </span>
          : <span className={'score-inner-text'}>
            {score}%
          </span>
      }
    </div>
  </div>
}

export default Score;