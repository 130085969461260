import { useMutation } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

interface SubmitAnswerParams {
    question_id: string;
    answer_text: string;
    portfolio_id: string;
}

export const useMutateQuizQuestionAnswer = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (params: SubmitAnswerParams) => {
            const { question_id, ...requestBody } = params;
            const response = await axios.patch(
                `/cpl-course-quizzes/quiz/question/response/${question_id}`,
                requestBody
            );
            return response.data;
        },
        retry: 1
    });
};